import { Center, Image } from '@chakra-ui/react';

export interface ImageThumbnailProps {
  url: string;
  height?: number | string;
  width: number | string;
  imageWidth: number;
  isClickable?: boolean;
  onZoom?: () => void;
}

const ImageThumbnail = ({ url, isClickable, height, width, onZoom }: ImageThumbnailProps) => (
  <Center
    bg="#EAEAEA"
    borderRadius={isClickable ? 4 : 8}
    cursor={onZoom ? 'zoom-in' : isClickable ? 'pointer' : 'auto'}
    position="relative"
    overflow="hidden"
    height={height}
    width={width}
    onClick={onZoom}
  >
    <Image src={url} width="100%" height="100%" />
  </Center>
);

export default ImageThumbnail;
