import { Box, Text } from '@chakra-ui/react';
import { ReactNode } from 'react';

interface FilterContainerProps {
  label: string;
  children: ReactNode;
}

const FilterContainer = ({ label, children }: FilterContainerProps) => (
  <Box w="100%">
    <Text color="secondaryDarkGray.600" mb="10px" textStyle="bodySmall">
      {label}:
    </Text>
    {children}
  </Box>
);

export default FilterContainer;
