import { useState, Fragment as F, useEffect } from 'react';

import { fabric } from 'fabric-spacerunners';

import { Box, Button as ChakraButton, Flex, HStack, Text } from '@chakra-ui/react';

import isEmpty from 'lodash/isEmpty';

import { QR_CODE_TYPE } from '../fabric';

import Button from '../../components/Button';
import IconUndo from '../../components/icons/IconUndo';
import IconRedo from '../../components/icons/IconRedo';

import { AiImage, Canvas, CanvasObject } from '../../types';

import ToolbarContainer from '../components/ToolbarContainer';

import ColorPicker from './ColorPicker';
import FontPicker from './FontPicker';

import ErrorModal from './ErrorModal';

import {
  IconColorPicker,
  IconTrash,
  IconLayerDown,
  IconLayerUp,
  IconCopy,
  IconCrop,
  IconEraser,
  IconFontSize,
  IconFontFamily,
  IconTextAlign,
  IconTextLeftAlign,
  IconTextCenter,
  IconTextRightAlign,
  IconRemoveBackground,
  IconAiEdit,
} from './Icons';

import ToolbarButton from '../components/ToolbarButton';
import { AbloSlider } from '../components/AbloSlider';
import { EDITOR_ERROR } from '@/lib/errors';
import { uploadBase64ToStorage } from '@/api/storage';

import IconBrush from '../icons/IconBrush';
import { DEFAULT_BRUSH_SIZE, getDrawingBrushCursor } from '../utils/inpainting-mask';

const CropMaskProps = {
  fill: 'rgba(0,0,0,0.3)',
  originX: 'left',
  originY: 'top',
  stroke: 'black',
  left: 0,
  top: 0,
  opacity: 1,
  width: 150,
  height: 150,
  hasRotatingPoint: false,
  transparentCorners: false,
  cornerColor: 'white',
  cornerStrokeColor: 'black',
  borderColor: 'black',
  cornerSize: 20 * 3,
  padding: 0,
  scaleX: 3,
  scaleY: 3,
  cornerStyle: 'circle',
  borderDashArray: [5, 5],
  borderScaleFactor: 1.3,
};

const CropButton = (props) => <Button height="28px" textTransform="none" w="91px" {...props} />;

const IconButton = ({ isSelected = false, ...rest }) => (
  <ChakraButton
    height="28px"
    bg={isSelected ? '#EDF2F7' : '#FFFFFF'}
    borderRadius="7px"
    border="1px solid"
    borderColor={isSelected ? 'brand.500' : '#D3D3D3'}
    padding="4px 6px"
    minWidth="auto"
    width="25px"
    {...rest}
  />
);

const TEXT_ALIGN_OPTIONS = [
  { name: 'left', icon: <IconTextLeftAlign />, iconActive: <IconTextLeftAlign isSelected /> },
  { name: 'center', icon: <IconTextCenter />, iconActive: <IconTextCenter isSelected /> },
  { name: 'right', icon: <IconTextRightAlign />, iconActive: <IconTextRightAlign isSelected /> },
];

const loadImageFromUrl = (url: string): Promise<string> =>
  new Promise((resolve) => {
    fabric.Image.fromURL(
      url,
      (img) => {
        resolve(img);
      },
      { crossOrigin: 'anonymous' }
    );
  });

type ObjectEditToolsProps = {
  activeObject: CanvasObject;
  canvas: Canvas;
  onCrop: (image: object) => void;
  onImageUpdate: (image: AiImage, preloadedImage?: string) => void;
  onSetActiveObject: (activeObject: CanvasObject) => void;
  onStateChange: () => void;
  onUndo: () => void;
  onRedo: () => void;
  removeBackgroundByUrl: (imageUrl: string) => Promise<string>;
  onAiEdit: () => void;
  isDrawingMask: boolean;
  maskCanvases: {
    canvas: CanvasObject;
    maskLayer: CanvasObject;
  };
  selectedTool: string;
  onChangeSelectedTool: (value: string) => void;
};

const ObjectEditTools = ({
  activeObject,
  canvas,
  maskCanvases,
  onCrop,
  onImageUpdate,
  onSetActiveObject,
  onStateChange,
  onRedo,
  onUndo,
  removeBackgroundByUrl,
  onAiEdit,
  isDrawingMask,
  selectedTool,
  onChangeSelectedTool,
}: ObjectEditToolsProps) => {
  const [removingBackground, setRemovingBackground] = useState(false);
  const [errorRemovingBackground, setErrorRemovingBackground] = useState(null);

  const [croppingMask, setCroppingMask] = useState(null);
  const [imageToCrop, setImageToCrop] = useState(null);
  const [isErasing, setErasing] = useState(false);

  const [pencilBrushSize, setPencilBrushSize] = useState(
    maskCanvases?.maskLayer?.freeDrawingBrush?.width || DEFAULT_BRUSH_SIZE
  );

  useEffect(() => {
    if (!activeObject && croppingMask) {
      canvas.remove(croppingMask);

      setCroppingMask(null);
    }
  }, [activeObject, canvas, croppingMask, setCroppingMask]);

  if (!activeObject) {
    return null;
  }

  const { aiImage, fill, fontFamily, fontSize, textAlign, text } = activeObject || {};
  const { url, noBackgroundUrl, withBackgroundUrl } = aiImage || {};

  const isBackgroundRemoved = url === noBackgroundUrl;

  const handleToggleBackground = async () => {
    if (isBackgroundRemoved) {
      onImageUpdate({
        ...aiImage,
        url: withBackgroundUrl,
      });

      return;
    }

    if (noBackgroundUrl) {
      onImageUpdate({
        ...aiImage,
        url: noBackgroundUrl,
      });

      return;
    }

    setRemovingBackground(true);

    try {
      let baseImageUrl = aiImage.url;
      if (aiImage.url.startsWith('data')) {
        const contentType = aiImage.url.split(';')[0].split(':')[1];
        baseImageUrl = await uploadBase64ToStorage(aiImage.url, contentType);
      }
      const url = await removeBackgroundByUrl(baseImageUrl);

      const img = await loadImageFromUrl(url);

      onImageUpdate(
        {
          ...aiImage,
          url,
          noBackgroundUrl: url,
          withBackgroundUrl: aiImage.url,
        },
        img
      );
    } catch (errResponse) {
      if (errResponse?.message === EDITOR_ERROR.OPERATION_NOT_ALLOWED) {
        setRemovingBackground(false);
      } else {
        const err = errResponse?.response?.data;

        setErrorRemovingBackground(err);
      }
    } finally {
      setRemovingBackground(false);
    }
  };

  const handleCrop = () => {
    if (isErasing) {
      canvas.isDrawingMode = false;

      setErasing(false);
    }

    if (croppingMask) {
      const rect = new fabric.Rect({
        left: croppingMask.left,
        top: croppingMask.top,
        width: croppingMask.getScaledWidth(),
        height: croppingMask.getScaledHeight(),
        absolutePositioned: true,
      });

      // add to the current image clipPath property
      activeObject.clipPath = rect;

      canvas.remove(croppingMask);

      const cropped = new Image();

      const backgroundImage = canvas.backgroundImage;
      const overlayImage = canvas.overlayImage;

      canvas.backgroundImage = null;
      canvas.overlayImage = null;

      cropped.src = canvas.toDataURL({
        left: rect.left,
        top: rect.top,
        width: rect.width,
        height: rect.height,
        multiplier: 5,
        format: 'png',
        quality: 0.99,
      });

      canvas.backgroundImage = backgroundImage;
      canvas.overlayImage = overlayImage;

      cropped.onload = function () {
        const image = new fabric.Image(cropped);
        image.left = rect.left;
        image.top = rect.top;
        image.aiImage = activeObject.aiImage;
        image.setCoords();
        image.scaleToWidth(rect.width);

        canvas.add(image);
        canvas.remove(imageToCrop);

        onCrop(image);
      };

      setCroppingMask(null);
      setImageToCrop(null);

      return;
    }

    const selectionRect = new fabric.Rect(CropMaskProps);

    selectionRect.setControlsVisibility({
      mt: true,
      mb: true,
      ml: true,
      mr: true,
    });

    setCroppingMask(selectionRect);
    setImageToCrop(activeObject);

    canvas.centerObject(selectionRect);

    canvas.add(selectionRect);
    canvas.setActiveObject(selectionRect);
    canvas.renderAll();
  };

  const cancelCrop = () => {
    canvas.remove(croppingMask);
    setCroppingMask(null);
    setImageToCrop(null);
  };

  const handleChangeBrushSize = (brushSize) => {
    maskCanvases.maskLayer.freeDrawingBrush.width = brushSize;

    maskCanvases.maskLayer.freeDrawingCursor = getDrawingBrushCursor(brushSize / 2);

    setPencilBrushSize(brushSize);
  };

  const handleErase = () => {
    if (canvas.isDrawingMode) {
      canvas.isDrawingMode = false;

      setErasing(false);

      return;
    }

    canvas.freeDrawingBrush = new fabric.EraserBrush(canvas);
    canvas.isDrawingMode = true;

    canvas.freeDrawingBrush.width = 10;

    setErasing(true);
  };

  const handleLayerDown = () => {
    const selectedObject = canvas.getActiveObject();

    canvas.sendBackwards(selectedObject);

    canvas.renderAll();

    onStateChange();
  };

  const handleLayerUp = () => {
    const selectedObject = canvas.getActiveObject();

    canvas.bringForward(selectedObject);
    canvas.renderAll();

    onStateChange();
  };

  const handleCopyActiveObject = () => {
    const activeObject = canvas.getActiveObject();

    activeObject.clone(
      (clone: CanvasObject) => {
        clone.set('left', activeObject.left + 10);
        clone.set('top', activeObject.top + 10);

        canvas.add(clone);
        canvas.bringForward(clone);
        canvas.setActiveObject(clone);
        canvas.renderAll();

        onStateChange();
      },
      ['aiImage']
    );
  };

  const handleUpdateActiveObject = (updates) => {
    if (activeObject._objects && updates.fill) {
      const { fill } = updates;

      activeObject._objects.forEach((object) => {
        object.set('fill', fill);
      });

      canvas.renderAll();

      return;
    }

    Object.keys(updates).forEach((key) => {
      canvas.getActiveObject().set(key, updates[key]);

      canvas.renderAll();
    });

    onSetActiveObject({ ...activeObject, ...updates });

    onStateChange();
  };

  const handleRemoveActiveObject = () => {
    const activeObject = canvas.getActiveObject();

    canvas.remove(activeObject);

    canvas.renderAll();

    onSetActiveObject(null);

    onStateChange();
  };

  const isText = !!text || (activeObject.fontFamily && activeObject.editable);
  const isQRCode = activeObject.type === QR_CODE_TYPE;
  const isSvg = !isEmpty(activeObject._objects) || activeObject.fromSVG;

  const isColorActive = selectedTool === 'color';
  const isFontSizeActive = selectedTool === 'fontSize';
  const isFontFamilyActive = selectedTool === 'fontFamily';
  const isTextAlignActive = selectedTool === 'textAlign';

  return (
    <ToolbarContainer>
      <HStack p="12px 11px 8px 14px" position="relative" overflow="auto" spacing="4px">
        {isDrawingMask ? (
          <HStack spacing="19px">
            <ToolbarButton icon={<IconBrush />} isReadOnly isSelected text="Draw Mask" />
            <AbloSlider
              defaultValue={pencilBrushSize}
              min={3}
              max={300}
              step={1}
              height="2px"
              shouldIncludeBox
              onChange={handleChangeBrushSize}
              value={pencilBrushSize}
              width="202px"
            />
          </HStack>
        ) : (
          <>
            {isText || isQRCode || isSvg ? (
              <ToolbarButton
                onClick={() => onChangeSelectedTool(isColorActive ? null : 'color')}
                icon={<IconColorPicker />}
                isSelected={isColorActive}
                text="Color"
              />
            ) : null}
            {isText ? (
              <F>
                <ToolbarButton
                  onClick={() => onChangeSelectedTool(isFontFamilyActive ? null : 'fontFamily')}
                  icon={<IconFontFamily />}
                  isSelected={isFontFamilyActive}
                  text="Style"
                />
                <ToolbarButton
                  onClick={() => onChangeSelectedTool(isFontSizeActive ? null : 'fontSize')}
                  icon={<IconFontSize />}
                  isSelected={isFontSizeActive}
                  text="Size"
                />
                <ToolbarButton
                  onClick={() => onChangeSelectedTool(isTextAlignActive ? null : 'textAlign')}
                  icon={<IconTextAlign />}
                  text="Align"
                />
              </F>
            ) : null}
            {activeObject?.aiImage ? (
              <ToolbarButton onClick={onAiEdit} icon={<IconAiEdit />} text="Ai Edit" />
            ) : null}
            {activeObject?.aiImage ? (
              <ToolbarButton
                isLoading={removingBackground}
                onClick={handleToggleBackground}
                icon={<IconRemoveBackground />}
                text={`${isBackgroundRemoved ? 'Restore' : 'Remove'} Bg`}
              />
            ) : null}
            <ToolbarButton onClick={handleLayerUp} icon={<IconLayerUp />} text="To Front" />
            <ToolbarButton onClick={handleLayerDown} icon={<IconLayerDown />} text="To Back" />
            {!isText && !isQRCode && (
              <ToolbarButton
                onClick={handleErase}
                icon={<IconEraser />}
                isSelected={isErasing}
                text="Eraser"
              />
            )}
            <ToolbarButton onClick={handleCopyActiveObject} icon={<IconCopy />} text="Duplicate" />
            <ToolbarButton onClick={handleRemoveActiveObject} icon={<IconTrash />} text="Delete" />
            {!isText && !isQRCode ? (
              <ToolbarButton onClick={handleCrop} icon={<IconCrop />} text="Crop" />
            ) : null}
            <ToolbarButton
              isDisabled={!onUndo}
              icon={<IconUndo isDisabled={!onUndo} />}
              onClick={onUndo}
              text="Undo"
            />
            <ToolbarButton
              isDisabled={!onRedo}
              icon={<IconRedo isDisabled={!onRedo} />}
              onClick={onRedo}
              text="Redo"
            />
          </>
        )}
      </HStack>
      <Box left={0} right={0} position="absolute" zIndex={2}>
        {isColorActive && (isText || isQRCode || isSvg) ? (
          <ColorPicker
            selectedColor={fill}
            onUpdateColor={(color) => {
              const updates = isQRCode ? { stroke: color } : { fill: color };

              handleUpdateActiveObject(updates);
            }}
          />
        ) : null}
        {isText ? (
          <Box pl="22px">
            {isFontSizeActive ? (
              <Flex align="center" mt="8px">
                <Text fontSize="12px">A</Text>
                <AbloSlider
                  defaultValue={12}
                  min={8}
                  max={200}
                  step={1}
                  margin="0 12px"
                  height="2px"
                  onChange={(val) => handleUpdateActiveObject({ fontSize: val })}
                  value={fontSize}
                  width="180px"
                  shouldIncludeBox={false}
                />
                <Text fontSize="24px">A</Text>
              </Flex>
            ) : null}
            {isFontFamilyActive ? (
              <FontPicker
                fontFamily={fontFamily}
                onUpdate={(fontFamily) => handleUpdateActiveObject({ fontFamily })}
              />
            ) : null}
            {isTextAlignActive ? (
              <HStack mt="8px">
                {TEXT_ALIGN_OPTIONS.map((option) => {
                  const { name, icon, iconActive } = option;

                  const isSelected = name === textAlign;

                  return (
                    <IconButton
                      onClick={() => handleUpdateActiveObject({ textAlign: name })}
                      isSelected={isSelected}
                    >
                      {isSelected ? iconActive : icon}
                    </IconButton>
                  );
                })}
              </HStack>
            ) : null}
          </Box>
        ) : null}
      </Box>
      {croppingMask ? (
        <HStack position="absolute" top="80px" right="20px" zIndex={2}>
          <CropButton onClick={handleCrop} title="Apply" />
          <CropButton onClick={cancelCrop} title="Cancel" variant="outlined" />
        </HStack>
      ) : null}
      {errorRemovingBackground ? (
        <ErrorModal
          error={errorRemovingBackground}
          onClose={() => setErrorRemovingBackground(null)}
        />
      ) : null}
    </ToolbarContainer>
  );
};

export default ObjectEditTools;
