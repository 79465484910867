import { Template } from '@/components/types';
import { sumBy } from 'lodash';

const ALL_TEMPLATES_OPTION = { id: null, name: 'All' };

export const getTemplateOptions = (templates: Template[]) => [
  { ...ALL_TEMPLATES_OPTION, description: `Products: ${sumBy(templates, 'numRemixes')}` },
  ...templates.map(({ id, name, iconUrl, numRemixes }) => ({
    id,
    name,
    iconUrl,
    description: `Products: ${numRemixes}`,
  })),
];
