import { useState } from 'react';

const useClickAndPan = (onClick) => {
  const [isDragging, setIsDragging] = useState(false);
  const [mouseDownPosition, setMouseDownPosition] = useState({ x: 0, y: 0 });
  const [clickTimeout, setClickTimeout] = useState(null);

  const handleMouseDown = (e) => {
    setIsDragging(false);
    setMouseDownPosition({ x: e.clientX, y: e.clientY });

    // Set a timeout to determine if it's a click
    const timeout = setTimeout(() => {
      setIsDragging(false);
    }, 200); // Adjust the timeout duration as needed

    setClickTimeout(timeout);
  };

  const handleMouseUp = (index) => {
    clearTimeout(clickTimeout); // Clear the timeout

    if (!isDragging) {
      onClick(index); // Call click handler if not dragging
    }
  };

  const handleMouseMove = (e) => {
    const dx = e.clientX - mouseDownPosition.x;
    const dy = e.clientY - mouseDownPosition.y;

    // If the mouse has moved more than a threshold, consider it a drag
    if (Math.abs(dx) > 5 || Math.abs(dy) > 5) {
      setIsDragging(true);
    }
  };

  return {
    handleMouseDown,
    handleMouseUp,
    handleMouseMove,
  };
};

export default useClickAndPan;
