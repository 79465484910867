import { Center, Flex, HStack, Text, VStack } from '@chakra-ui/react';

import { Design } from '@/lib';
import { getDesign } from '@/api/designs';
import { useEffect, useState } from 'react';

import AppContainer from '@/layouts/AppContainer';
import { useHistory, useLocation } from 'react-router-dom';

import LoadingSpinner from '@/components/ui/LoadingSpinner';

import IconChevronRight from '@/components/icons/IconChevronRight';
import SubmitDesignSideImage from './components/SubmitDesignSideImage';
import Button from '@/components/button';

export default function SubmitRemix() {
  const [design, setDesign] = useState<Design>(null);

  const [isLoading, setLoading] = useState(true);

  const { search } = useLocation();

  const searchParams = new URLSearchParams(search);

  const designId = searchParams.get('designId');

  const history = useHistory();

  useEffect(() => {
    getDesign(designId)
      .then((design) => setDesign(design))
      .finally(() => setLoading(false));
  }, []);

  const handleGoBackToDesignStudio = () => history.push(`/designs/${design.id}`);

  const handleGoToPublishStep = () => history.push(`/submit/publish?designId=${designId}`);

  return (
    <AppContainer contentContainerProps={{ p: 0 }}>
      {isLoading ? (
        <Center bg="transparent" h="100%">
          <LoadingSpinner />
        </Center>
      ) : (
        <Flex
          bg="#FFFFFF"
          direction="column"
          h="calc(100dvh - 70px)"
          p="12px 16px 84px 16px"
          w="100%"
        >
          <HStack gap="4px" mb="12px">
            <Text color="brand.600" onClick={handleGoBackToDesignStudio} textStyle="breadcrumb">
              Design Studio
            </Text>
            <IconChevronRight />
            <Text color="secondaryDarkGray.600" fontWeight={400} textStyle="breadcrumb">
              Review
            </Text>
          </HStack>
          <VStack flex={1} overflow="auto">
            {design.sides.map((side) => {
              return (
                <SubmitDesignSideImage
                  borderRadius="12px"
                  design={design}
                  sideName={side.templateSide.name}
                  imageWidth="90%"
                  height="342px"
                  width="100%"
                />
              );
            })}
          </VStack>
          <HStack bottom="28px" left="16px" right="16px" position="fixed" spacing="12px">
            <Button onClick={handleGoBackToDesignStudio} flex={1} secondary>
              Go Back
            </Button>
            <Button onClick={handleGoToPublishStep} flex={1}>
              Publish Design
            </Button>
          </HStack>
        </Flex>
      )}
    </AppContainer>
  );
}
