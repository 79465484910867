import { Design } from '@/lib';
import { getFormattedAmount } from '@/views/admin/utils/currency-formatter';
import { Flex, Text, VStack } from '@chakra-ui/react';

interface RoyaltyModuleProps {
  product: Design;
}

const RoyaltyModule = ({ product }: RoyaltyModuleProps) => {
  const {
    template: { currency, price, royaltyPercentage },
  } = product;

  const userEarnings = (royaltyPercentage / 100) * price;

  const lineItems = [
    { name: 'Product price', value: price },
    {
      name: 'Royalty earnings',
      value: userEarnings,
      percentage: royaltyPercentage,
    },
  ];

  return (
    <VStack w="100%">
      <Flex justify="space-between" w="100%">
        <Text as="b" textStyle="bodySmall">
          Your earnings from each sale
        </Text>
        <Text as="b" textStyle="bodySmall">
          {getFormattedAmount(currency?.name, userEarnings)}
        </Text>
      </Flex>
      <VStack w="100%">
        {lineItems.map(({ name, value, percentage }, index) => (
          <Flex justify="space-between" key={index} w="100%">
            <Text textStyle="bodySmall">{name}</Text>
            <Text textStyle="bodySmall">
              {getFormattedAmount(currency?.name, value)}
              {percentage ? ` (${percentage}%)` : ''}
            </Text>
          </Flex>
        ))}
      </VStack>
    </VStack>
  );
};

export default RoyaltyModule;
