import { isEmpty } from 'lodash';
import MultiSelectMenu, { MultiSelectMenuProps } from './MultiSelectMenu';

export interface SelectMenuProps<T>
  extends Omit<MultiSelectMenuProps<T>, 'onUpdateSelectedValues' | 'selectedValues'> {
  selectedValue: T;
  onUpdateSelectedValue: (values: T) => void;
}

const SelectMenu = function <T>({
  selectedValue,
  onUpdateSelectedValue,
  ...rest
}: SelectMenuProps<T>) {
  const handleUpdateSelectedValues = (values) => {
    if (isEmpty(values)) {
      onUpdateSelectedValue(selectedValue);
    } else {
      const newValue = values.filter((value) => selectedValue !== value);

      onUpdateSelectedValue(newValue[0]);
    }
  };

  return (
    <MultiSelectMenu
      onUpdateSelectedValues={handleUpdateSelectedValues}
      selectedValues={[selectedValue]}
      {...rest}
    />
  );
};

export default SelectMenu;
