import { useCallback, useEffect, useRef, useState } from 'react';

import { Box, Flex, HStack, Show, SimpleGrid, Text } from '@chakra-ui/react';

import { Design } from '@/lib';

import { getDesigns } from '@/api/designs';
import { Template, User } from '@/components/types';
import { isEmpty, uniqBy } from 'lodash';

import { useDebouncedValue } from '@/hooks/useDebouncedValue';
import { useHistory, useLocation } from 'react-router-dom';
import Checkbox from '@/components/ui/checkbox';
import LoadingSpinner from '@/components/ui/LoadingSpinner';
import ProductCard from './ProductCard';
import Search from '@/components/search';
import EmptyState from './EmptyState';
import CardsDisplayToggle from './cards-display-toggle';
import { trackEvent } from '@/analytics';
import { getTemplates, useCategories } from '@/api/templates';

import Select from '@/components/select/Select';

import ButtonToggleFilters from './ButtonToggleFilters';
import MobileFilterModal from './filter-modal';
import { getTemplateOptions } from '../utils';
import CategoryTabs from './CategoryTabs';

const ALL_CATEGORIES_OPTION = {
  name: 'All',
  id: null,
};

interface ProductListProps {
  maxItems?: number;
  user?: User;
  brandId?: string;
}

interface MyDesign extends Design {
  isMyDesign: true;
}

export default function ProductList({ maxItems, user, brandId }: ProductListProps) {
  const history = useHistory();
  const location = useLocation();
  const { search: searchParams } = location;
  const queryParams = new URLSearchParams(searchParams);

  const [designs, setDesigns] = useState<Design[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [hasAllItems, setHasAllItems] = useState(false);
  const [isLoadingNextPage, setLoadingNextPage] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [myDesigns, setMyDesigns] = useState<MyDesign[]>([]);
  const [templates, setTemplates] = useState<Template[]>([]);
  const [areFiltersVisible, setAreFiltersVisible] = useState(false);
  const [isLargeCardView, setLargeCardView] = useState(true);
  const [isShowingMyDesigns, setIsShowingMyDesigns] = useState(
    queryParams.get('isShowingMyDesigns')
  );
  const [searchTerm, setSearchTerm] = useState(queryParams.get('search') || undefined);
  const [templateId, setTemplateId] = useState<string>(queryParams.get('templateId') || null);
  const [categoryId, setCategoryId] = useState<string>(queryParams.get('categoryId') || null);

  const observer = useRef<IntersectionObserver>(null);
  const highlightedDesignRef = useRef(null);

  const ITEMS_PER_PAGE = 8;

  const { data: categories = [] } = useCategories();

  const debouncedSearchTerm = useDebouncedValue(searchTerm, 500);

  const highlightedDesignId = queryParams.get('highlightedDesignId');

  useEffect(() => {
    setHasAllItems(false);
    setLoadingNextPage(true);

    const searchParams = new URLSearchParams();

    searchParams.append('page', '1');

    if (debouncedSearchTerm) searchParams.append('search', `${debouncedSearchTerm}`);

    searchParams.append('categoryId', `${categoryId}`);

    if (templateId) searchParams.append('templateId', `${templateId}`);

    history.replace({
      pathname: location.pathname,
      search: searchParams.toString(),
    });

    getDesigns({
      brandId: brandId ? brandId : null,
      isPublished: true,
      categoryId: templateId ? null : categoryId,
      templateId,
      take: maxItems || ITEMS_PER_PAGE,
      skip: 0,
      search: debouncedSearchTerm || null,
    }).then(({ designs, total }) => {
      setDesigns(designs);
      setTotal(total);

      if (debouncedSearchTerm) {
        trackEvent('search_interaction', {
          search_term: debouncedSearchTerm,
          result_count: total,
          search_location: 'products_page',
        });
      }

      if (maxItems || designs.length < ITEMS_PER_PAGE) {
        setHasAllItems(true);
      }

      setLoadingNextPage(false);
    });
  }, [debouncedSearchTerm, setCurrentPage, templateId, categoryId]);

  useEffect(() => {
    if (maxItems) {
      return;
    }

    const loadInitialData = async () => {
      let myDesignsResponse = { designs: [] };

      if (user?.id) {
        myDesignsResponse = await getDesigns({
          brandId: brandId ? brandId : null,
          isPublished: true,
          userId: user.id,
          take: 1000 * 1000,
          skip: 0,
        });
      }

      const myDesigns = myDesignsResponse.designs.map((design) => ({
        ...design,
        isMyDesign: true,
      }));

      setMyDesigns(myDesigns);
    };

    loadInitialData();
  }, []);

  useEffect(() => {
    if (currentPage === 1) return;

    const searchParams = new URLSearchParams();

    searchParams.append('page', `${currentPage}`);
    searchParams.append('categoryId', `${categoryId}`);

    if (templateId) searchParams.append('templateId', `${templateId}`);

    history.replace({
      pathname: location.pathname,
      search: searchParams.toString(),
    });

    setLoadingNextPage(true);

    getDesigns({
      brandId: brandId ? brandId : null,
      isPublished: true,
      categoryId: templateId ? null : categoryId,
      templateId,
      take: ITEMS_PER_PAGE,
      skip: (currentPage - 1) * ITEMS_PER_PAGE,
    }).then(({ designs, total }) => {
      setDesigns((oldDesigns) => (maxItems ? designs : uniqBy([...oldDesigns, ...designs], 'id')));

      setTotal(total);

      if (designs.length < ITEMS_PER_PAGE) setHasAllItems(true);

      setLoadingNextPage(false);
    });
  }, [currentPage, maxItems, categoryId, templateId]);

  const lastElementRef = useCallback(
    (node) => {
      if (isLoadingNextPage || hasAllItems) {
        return;
      }

      if (observer.current) {
        observer.current.disconnect();
      }

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setCurrentPage((prevPage) => prevPage + 1);
        }
      });

      if (node) {
        observer.current.observe(node);
      }
    },
    [isLoadingNextPage, hasAllItems]
  );

  useEffect(() => {
    getTemplates({
      isDefault: true,
      categoryId: !categoryId || categoryId === 'null' ? null : categoryId,
    }).then((templates) => {
      setTemplates(templates);
    });
  }, [categoryId]);

  const handleSelectedTemplate = (id) => {
    setCurrentPage(1);

    setTemplateId(id);
  };

  const handleShowMyDesigns = (isChecked) => {
    setIsShowingMyDesigns(isChecked);
  };

  const trackSelectCollectionEvent = (categoryId) =>
    trackEvent('collection_page_view', {
      category_filter: categories.find(({ id }) => id === categoryId)?.name,
      total_products: total,
      user_merch_count: myDesigns.length,
    });

  const categoryOptions = [ALL_CATEGORIES_OPTION, ...categories];

  const templateOptions = getTemplateOptions(templates);

  return (
    <Flex direction="column" flex={1} padding={{ base: '16px 16px 80px 16px', md: '24px 32px' }}>
      {!maxItems ? (
        <Show above="md">
          <CategoryTabs
            tabs={categoryOptions}
            selectedValue={categoryId}
            onSelectedValue={(categoryId) => {
              setCurrentPage(1);

              setCategoryId(categoryId);
              setTemplateId(null);

              trackSelectCollectionEvent(categoryId);
            }}
          />
        </Show>
      ) : null}
      <Box mb={{ base: '0px', md: '24px' }} />
      <Flex direction="column" h="100%" w="100%" overflow="visible">
        <Show below="md">
          {!maxItems ? (
            <HStack gap="18px">
              <ButtonToggleFilters
                isVisible={areFiltersVisible}
                onToggleVisible={setAreFiltersVisible}
                count={templateId ? 1 : 0}
              />
              <Search value={searchTerm} onChange={setSearchTerm} width="100%" />
            </HStack>
          ) : null}
        </Show>
        {!maxItems ? (
          <>
            <Flex
              align="center"
              justify="space-between"
              mb={{ base: '12px', md: '16px' }}
              mt={{ base: '18px', md: 0 }}
              pr={{ base: 0, md: '8px' }}
            >
              <HStack spacing="27px">
                <Text fontWeight={600} textStyle="body">
                  {`Products (${total})`}
                </Text>
                {user ? (
                  <Checkbox
                    isChecked={isShowingMyDesigns}
                    onChange={(e) => handleShowMyDesigns(e.target.checked)}
                  >
                    <Text textStyle="body">My merch ({myDesigns.length})</Text>
                  </Checkbox>
                ) : null}
              </HStack>
              <HStack>
                <Show above="md">
                  <Search value={searchTerm} onChange={setSearchTerm} />
                </Show>
                <HStack>
                  <CardsDisplayToggle
                    isLargeCardView={isLargeCardView}
                    onChange={setLargeCardView}
                  />
                  <Show above="md">
                    <ButtonToggleFilters
                      isVisible={areFiltersVisible}
                      onToggleVisible={setAreFiltersVisible}
                      count={templateId ? 1 : 0}
                    />
                  </Show>
                </HStack>
              </HStack>
            </Flex>
            {areFiltersVisible ? (
              <Flex align="center" mb="16px">
                <Select
                  label="Template"
                  options={templateOptions}
                  onUpdateSelectedValue={handleSelectedTemplate}
                  onClear={() => setTemplateId(null)}
                  selectedValue={templateId}
                  width="384px"
                />
              </Flex>
            ) : null}
          </>
        ) : null}
        {!isShowingMyDesigns && !isLoadingNextPage && isEmpty(designs) ? (
          <EmptyState searchTerm={searchTerm} />
        ) : (
          <SimpleGrid
            columns={{
              sm: isLargeCardView ? 1 : 2,
              '2sm': isLargeCardView ? 2 : 3,
              md: isLargeCardView ? 4 : 6,
            }}
            spacing="12px"
            w="100%"
          >
            {[...designs, ...myDesigns].map((design, index) => {
              const { isMyDesign } = design as MyDesign;

              return (
                <Box
                  key={design.id + index}
                  display={
                    (isMyDesign && isShowingMyDesigns) || (!isMyDesign && !isShowingMyDesigns)
                      ? 'block'
                      : 'none'
                  }
                  mb="12px"
                  ref={
                    index === designs.length - 1
                      ? lastElementRef
                      : design.id === highlightedDesignId
                      ? highlightedDesignRef
                      : null
                  }
                >
                  <ProductCard categories={categories} design={design} />
                </Box>
              );
            })}
          </SimpleGrid>
        )}
        {isLoadingNextPage ? <LoadingSpinner mt="16px" /> : null}
        {areFiltersVisible ? (
          <Show below="md">
            <MobileFilterModal
              categoryOptions={categoryOptions}
              onClose={() => setAreFiltersVisible(false)}
              onFiltersUpdate={({ categoryId, templateId }) => {
                setTemplateId(templateId);
                setCategoryId(categoryId);
                setCurrentPage(1);

                trackSelectCollectionEvent(categoryId);

                setAreFiltersVisible(false);
              }}
              filters={{
                categoryId,
                templateId,
              }}
            />
          </Show>
        ) : null}
      </Flex>
    </Flex>
  );
}
