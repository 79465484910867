import { Icon } from '@chakra-ui/react';

const IconAll = ({ color = null }) => (
  <Icon
    width="18px"
    height="10px"
    viewBox="0 0 18 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 0H18V1.4H0V0ZM2 4H16V5.4H2V4ZM14 8H4V9.4H14V8Z"
      fill={color || '#064AC4'}
    />
  </Icon>
);

export default IconAll;
