import GuestPageContainer from '@/layouts/GuestPageContainer';

import ProductsPage from './ProductsPage';
import { useState } from 'react';

export default function ProductsPageGuest() {
  const [location, setLocation] = useState<string>('/products');
  const [isSignInModalVisible, setSignInModalVisible] = useState(false);

  const handleSignedIn = () => {
    window.location.href = location;
    setSignInModalVisible(false);
  };

  const onDesignClicked = (templateId?: string) => {
    if (templateId) {
      setLocation(`/designs/draft?templateId=${templateId}`);
    } else {
      setLocation('/designs/new');
    }
    setSignInModalVisible(true);
  };

  return (
    <GuestPageContainer
      showSignInModal={isSignInModalVisible}
      onCancelSignIn={() => setSignInModalVisible(false)}
      onSignedIn={handleSignedIn}
      pageName="Merch Shop"
    >
      <ProductsPage onDesignClicked={onDesignClicked} />
    </GuestPageContainer>
  );
}
