import { useState } from 'react';

import { createDesign } from '@/api/designs';

import DesignPreview from './DesignPreview';
import { useParams } from 'react-router-dom';
import GuestPageContainer from '@/layouts/GuestPageContainer';
import { validate as isValidUUID } from 'uuid';
import { Center, Text } from '@chakra-ui/react';

export default function DesignPreviewPage() {
  const [isSignInModalVisible, setSignInModalVisible] = useState(false);

  const [isDuplicating, setIsDuplicating] = useState(false);

  const { id: designId } = useParams<{ id: string }>();

  const handleDuplicate = () => {
    setIsDuplicating(true);

    setSignInModalVisible(true);
  };

  const handleCancelSignIn = () => {
    setIsDuplicating(false);

    setSignInModalVisible(false);
  };

  const handleSignedIn = () => {
    if (!isDuplicating) {
      window.location.reload();

      return;
    }

    createDesign({ id: designId }).then((newDesign) => {
      window.location.href = `/designs/${newDesign.id}`;
    });
  };

  return (
    <GuestPageContainer
      showSignInModal={isSignInModalVisible}
      onCancelSignIn={handleCancelSignIn}
      onSignedIn={handleSignedIn}
      pageName="Design Preview"
    >
      {isValidUUID(designId) ? (
        <DesignPreview onDuplicate={handleDuplicate} />
      ) : (
        <Center p="16px">
          <Text>Invalid design ID</Text>
        </Center>
      )}
    </GuestPageContainer>
  );
}
