import { Box, Center, Flex, HStack, Image, MenuItem, Text, VStack } from '@chakra-ui/react';

import { Option } from '@/components/types';
import IconCheckmark from './icons/IconCheckmark';
import IconAll from './icons/IconAll';

const BUTTON_SIZE = 38;

const HIGHLIGHT_COLOR = 'lightGray.400';

export interface MultiSelectMenuProps<T> {
  customOptionRenderer?: (name: string, id: T) => React.ReactNode;
  onUpdateSelectedValues: (values: T[]) => void;
  options: Option<T>[];
  isStandAlone?: boolean;
  selectedValues: T[];
  width?: string;
}

const MultiSelectMenu = function <T>({
  customOptionRenderer,
  options,
  selectedValues = [],
  isStandAlone,
  onUpdateSelectedValues,
}: MultiSelectMenuProps<T>) {
  const toggleSelected = (value) => {
    let newSelected;

    if (selectedValues.includes(value)) {
      newSelected = selectedValues.filter((s) => s !== value);
    } else {
      newSelected = [...selectedValues, value];
    }

    onUpdateSelectedValues(newSelected);
  };

  return (
    <>
      {options.map(({ id, name, iconUrl, description }, index) => {
        const isSelected = selectedValues.includes(id);

        const Component = isStandAlone ? Box : MenuItem;

        return (
          <Component
            key={index}
            borderRadius="4px"
            mb="6px"
            bg={isSelected ? HIGHLIGHT_COLOR : 'transparent'}
            onClick={() => toggleSelected(id)}
            _hover={{ bg: HIGHLIGHT_COLOR }}
            _focus={{ bg: HIGHLIGHT_COLOR }}
            p="4px 15px 4px 4px"
          >
            <Flex align="center" justify="space-between" w="100%">
              {customOptionRenderer ? (
                customOptionRenderer(name, id)
              ) : (
                <HStack>
                  {!id ? (
                    <Center h={`${BUTTON_SIZE}px`} w={`${BUTTON_SIZE}px`}>
                      <IconAll color={!isSelected && '#000000'} />
                    </Center>
                  ) : iconUrl ? (
                    <Image h={`${BUTTON_SIZE}px`} w={`${BUTTON_SIZE}px`} src={iconUrl} />
                  ) : null}
                  <VStack align="flex-start" spacing={0}>
                    <Text textStyle="bodySmall">{name}</Text>
                    <Text color="secondaryDarkGray.600" textStyle="bodySuperSmall">
                      {description}
                    </Text>
                  </VStack>
                </HStack>
              )}
              {isSelected ? <IconCheckmark /> : null}
            </Flex>
          </Component>
        );
      })}
    </>
  );
};

export default MultiSelectMenu;
