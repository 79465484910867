import { Icon } from '@chakra-ui/react';

const IconHeart = () => (
  <Icon
    width="16px"
    height="16px"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.50031 3.22181C6.97161 2.59489 5.95442 1.99289 5.04487 1.99286C4.56345 1.99336 4.08688 2.08906 3.6426 2.27445C3.19831 2.45985 2.79507 2.73127 2.45608 3.07311C1.01022 4.52511 1.01084 6.79621 2.45731 8.24207L6.96264 12.7474C7.0671 12.9311 7.26865 13.0497 7.50031 13.0497C7.59543 13.0488 7.68901 13.0256 7.77353 12.9819C7.85805 12.9383 7.93115 12.8754 7.98697 12.7984L12.5433 8.24207C13.9898 6.7956 13.9898 4.52511 12.5421 3.07065C12.2033 2.72944 11.8003 2.45857 11.3565 2.2736C10.9126 2.08864 10.4366 1.99323 9.95575 1.99286C9.04622 1.99301 7.93474 2.59489 7.50031 3.22181ZM11.6732 3.93952C12.6336 4.90486 12.6343 6.41278 11.6744 7.37321L7.50031 11.5473L3.32618 7.37321C2.36637 6.41278 2.36698 4.90486 3.32495 3.94197C3.79195 3.47743 4.40274 3.22181 5.04487 3.22181C5.68699 3.22181 6.29532 3.47743 6.75864 3.94075L7.06588 4.24798C7.12289 4.3051 7.19061 4.35041 7.26515 4.38132C7.3397 4.41224 7.41961 4.42815 7.50031 4.42815C7.58101 4.42815 7.66092 4.41224 7.73547 4.38132C7.81001 4.35041 7.87773 4.3051 7.93474 4.24798L8.24198 3.94075C9.17107 3.0135 10.7454 3.01596 11.6732 3.93952Z"
      fill="#212529"
    />
    <path
      d="M7.25728 3.69383C6.99698 3.78056 6.44751 3.20222 4.71226 2.59489C4.44233 2.77805 3.8215 3.20222 3.49758 3.43359C3.09269 3.72279 2.51426 4.4169 2.25397 5.13992C2.04574 5.71834 2.57211 7.13546 2.86132 7.77172L7.48866 12.0809C8.35629 11.2712 10.2188 9.54168 10.7278 9.10208C11.3641 8.55259 12.4631 7.33791 12.839 6.44136C13.215 5.54481 12.6655 5.71834 12.6077 5.13992C12.5498 4.5615 12.5498 4.79287 12.4631 4.15661C12.3763 3.52035 11.5376 3.28898 11.0749 3.14438C10.6121 2.99977 10.2651 2.88409 9.19499 2.97085C8.12492 3.05761 7.51758 3.60711 7.25728 3.69383Z"
      fill="#212529"
    />
  </Icon>
);

export default IconHeart;
