import { ReactNode, useState } from 'react';
import { Box, ButtonProps, Flex } from '@chakra-ui/react';

import { TextToImageResponse, Template } from '@/lib/types';

import EditorToolbar from './toolbar';

import TextToImageGenerator from './toolbar/text-to-image';
import ImageToImageGenerator from './toolbar/image-to-image';
import FontToImageGenerator from './toolbar/font-to-image';
import { IAbloImage } from '@space-runners/ablo-ts-sdk/lib/interfaces/ablo-image.interface';
import { IStyle } from '@space-runners/ablo-ts-sdk/lib/services/style/style.interface';
import { IImageFileToImageRequest } from '@space-runners/ablo-ts-sdk/lib/services/photo-transformer/image-file-to-image-request.interface';
import { IFontMakerRequest } from '@space-runners/ablo-ts-sdk/lib/services/font-maker/font-maker-request.interface';

import ToolType from './toolbar/ToolTypes';
import { Favorite } from '@/lib';
import FavoriteGallery from './toolbar/components/FavoriteGallery';
import { CROCS } from '../utils/special-brands';
import { IImageMakerRequest } from '@space-runners/ablo-ts-sdk';

type EditorContainerProps = {
  actionCosts?: {
    [key in ToolType]?: number;
  };
  onImagesPreview?: (images: IAbloImage[]) => void;
  onGeneratedImageSelected: (image: IAbloImage) => void;
  isEditorToolbarExpanded: boolean;
  isEditing: boolean;
  onChangeEditorToolbarExpanded: (isExpanded: boolean) => void;
  children: React.ReactNode;
  styles: IStyle[];
  generateImageFromText: (options: IImageMakerRequest) => Promise<TextToImageResponse>;
  generateImageFromImage?: (
    options: IImageFileToImageRequest,
    contentType: string
  ) => Promise<IAbloImage[]>;
  generateImageFromFont?: (options: IFontMakerRequest) => Promise<IAbloImage[]>;
  hideStyles?: boolean;
  customToolbarContent?: ReactNode;
  buttonProps?: {
    basic: ButtonProps;
    outlined: ButtonProps;
  };
  availableTools?: ToolType[];
  subjectText?: string;
  addFavorite: (favorite: Favorite) => void;
  favorites: Favorite[];
  removeFavorite: (id: string) => void;
  template: Template;
  isAiModeActive?: boolean;
};

export default function EditorContainer({
  actionCosts = {},
  onImagesPreview,
  onGeneratedImageSelected,
  isEditing,
  isEditorToolbarExpanded,
  onChangeEditorToolbarExpanded,
  children,
  styles,
  generateImageFromText,
  generateImageFromImage,
  generateImageFromFont,
  hideStyles,
  customToolbarContent,
  availableTools,
  buttonProps,
  subjectText,
  addFavorite,
  favorites,
  removeFavorite,
  template,
  isAiModeActive,
}: EditorContainerProps) {
  const [selectedTool, setSelectedTool] = useState(
    availableTools ? availableTools[0] : ToolType.TEXT_TO_IMAGE
  );

  const isImageToImage = selectedTool === ToolType.IMAGE_TO_IMAGE;
  const isCrocs = template?.brands?.find((b) => b.name === CROCS);

  return (
    <Flex
      align="center"
      bg={isAiModeActive ? '#2D3748' : '#F9F9F7'}
      flexDirection={{ base: 'column', lg: 'row' }}
      paddingBottom={{ base: isEditing ? 0 : '180px', lg: 0 }}
      overflowY="auto"
      h={`calc(100% - ${template?.sides?.length > 1 ? 63 : 0}px)`}
      w="100%"
    >
      <EditorToolbar
        isExpanded={isEditorToolbarExpanded}
        onSetExpanded={onChangeEditorToolbarExpanded}
        onGeneratedImageSelected={onGeneratedImageSelected}
        selectedTool={selectedTool}
        onSelectedTool={setSelectedTool}
        customToolbarContent={customToolbarContent}
        hideButtons={
          !!customToolbarContent || (isEditorToolbarExpanded && availableTools?.length === 1)
        }
        availableTools={availableTools}
      >
        <Box display={customToolbarContent ? 'none' : 'block'}>
          <Box display={selectedTool === ToolType.TEXT_TO_IMAGE ? 'block' : 'none'}>
            <TextToImageGenerator
              actionCost={actionCosts[ToolType.TEXT_TO_IMAGE]}
              styles={styles.filter(({ type }) => type === 'text')}
              generateImageFromText={generateImageFromText}
              hideStyles={hideStyles}
              onGeneratedImageSelected={onGeneratedImageSelected}
              onImagesPreview={onImagesPreview}
              buttonProps={buttonProps}
              subjectText={subjectText}
              addFavorite={addFavorite}
              favorites={favorites}
              removeFavorite={removeFavorite}
              shouldApplyBadWordGuard={isCrocs ? 'true' : 'false'}
              ipInfringementErrorThreshold={isCrocs ? '80' : '100'}
            />
          </Box>
          <Box display={isImageToImage ? 'block' : 'none'}>
            <ImageToImageGenerator
              actionCost={actionCosts[ToolType.IMAGE_TO_IMAGE]}
              styles={styles.filter(({ type }) => type === 'image')}
              generateImageFromImage={generateImageFromImage}
              onGeneratedImageSelected={onGeneratedImageSelected}
              addFavorite={addFavorite}
              favorites={favorites}
              removeFavorite={removeFavorite}
            />
          </Box>
          <Box display={selectedTool === ToolType.FONT_TO_IMAGE ? 'block' : 'none'}>
            <FontToImageGenerator
              actionCost={actionCosts[ToolType.FONT_TO_IMAGE]}
              styles={styles.filter(({ type }) => type === 'font')}
              generateImageFromFont={generateImageFromFont}
              onGeneratedImageSelected={onGeneratedImageSelected}
              addFavorite={addFavorite}
              favorites={favorites}
              removeFavorite={removeFavorite}
            />
          </Box>
          <Box padding="18px 20px" display={selectedTool === ToolType.FAVORITES ? 'block' : 'none'}>
            <FavoriteGallery
              favorites={favorites}
              removeFavorite={removeFavorite}
              onGeneratedImageSelected={onGeneratedImageSelected}
            />
          </Box>
        </Box>
      </EditorToolbar>
      <Box
        display="flex"
        flex={1}
        flexDirection="column"
        h={{ base: 'auto', lg: '100%' }}
        w="100%"
        position={{ base: 'static', lg: 'relative' }}
      >
        {children}
      </Box>
    </Flex>
  );
}
