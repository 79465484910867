import { Box, Flex, Hide, Icon, VStack, Text } from '@chakra-ui/react';

import { ReactNode, useEffect, useState } from 'react';

import ToolType from './ToolTypes';
import { VIEWS } from './ToolbarViews';
import { isNumber } from 'lodash';
import { IAbloImage } from '@space-runners/ablo-ts-sdk/lib/interfaces/ablo-image.interface';

const IconDragHandle = ({ rotate }) => (
  <Icon
    position="relative"
    top="5px"
    width="28px"
    height="11px"
    viewBox="0 0 28 11"
    fill="none"
    {...(rotate ? { transform: 'rotate(180deg)' } : {})}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 2L14 9L26 2"
      stroke="#D4D4D3"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);

const MIN_OVERLAY_HEIGHT = 95;
const MAX_OVERLAY_HEIGHT = 'max-content';

const EXPAND_TO_FULL_HEIGHT_THRESHOLD = 300;

type FooterToolbarProps = {
  isExpanded: boolean;
  onSetExpanded: (isExpaned: boolean) => void;
  onGeneratedImageSelected: (image: IAbloImage) => void;
  selectedTool: ToolType;
  onSelectedTool: (tool: ToolType) => void;
  children: ReactNode;
  customToolbarContent?: ReactNode;
  hideButtons?: boolean;
  availableTools?: ToolType[];
};

export default function EditorToolbar(props: FooterToolbarProps) {
  const {
    children,
    isExpanded,
    onSetExpanded,
    onSelectedTool,
    selectedTool,
    hideButtons,
    availableTools,
    customToolbarContent,
  } = props;

  const [height, setHeight] = useState<number | string>(MIN_OVERLAY_HEIGHT);

  const [drag, setDrag] = useState({
    active: false,
  });

  useEffect(() => {
    if (isExpanded) {
      setHeight(MAX_OVERLAY_HEIGHT);
    } else {
      setHeight(MIN_OVERLAY_HEIGHT);
    }
  }, [isExpanded, selectedTool]);

  const startResize = () => {
    setDrag({
      active: true,
    });
  };

  const endResize = () => {
    const newHeight = document.getElementById('toolbarOverlay')?.clientHeight || 0;

    if (newHeight > EXPAND_TO_FULL_HEIGHT_THRESHOLD) {
      setHeight(MAX_OVERLAY_HEIGHT);

      onSetExpanded(true);
    } else {
      setHeight(MIN_OVERLAY_HEIGHT);

      onSetExpanded(false);
    }

    setDrag({
      active: false,
    });
  };

  const resize = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent> | React.TouchEvent<HTMLDivElement>
  ) => {
    const { active } = drag;

    if (!active) {
      return;
    }

    e.stopPropagation();

    const containerHeight = window.innerHeight || 0;

    let clientY = 0;

    if (e.type === 'touchmove' || e.type === 'touchstart') {
      const { touches, changedTouches } = e as React.TouchEvent;

      const touch = touches[0] || changedTouches[0];

      clientY = touch.pageY;
    } else {
      clientY = (e as React.MouseEvent).clientY;
    }

    const newHeight = containerHeight - clientY;

    if (newHeight > EXPAND_TO_FULL_HEIGHT_THRESHOLD) {
      setHeight(MAX_OVERLAY_HEIGHT);

      onSetExpanded(true);
    } else if (newHeight < MIN_OVERLAY_HEIGHT) {
      setHeight(MIN_OVERLAY_HEIGHT);
    } else {
      setHeight(newHeight);
    }
  };

  const handleToolChange = (name) => {
    onSelectedTool(name);

    onSetExpanded(true);
  };

  const { active } = drag;

  const isFullHeight = height === MAX_OVERLAY_HEIGHT;

  const availableViews = availableTools
    ? availableTools.map((tool) => VIEWS.find(({ name }) => name === tool))
    : VIEWS;

  return (
    <Box
      borderRadius={{ base: '24px 24px 0 0', lg: 0 }}
      bottom={0}
      id="toolbarOverlay"
      bg="#FFFFFF"
      h={{ base: isNumber(height) ? `${height}px` : MAX_OVERLAY_HEIGHT, lg: '100%' }}
      maxH={{ base: 'calc(100% - 40px)', lg: 'none' }}
      overflow={{
        base: height === MIN_OVERLAY_HEIGHT ? 'none' : 'auto',
        lg: 'auto',
      }}
      pb={{ base: '10px', lg: '14px' }}
      position={{ base: 'fixed', lg: 'relative' }}
      zIndex={2}
      w={{ base: '100%', lg: '393px' }}
    >
      <Box
        onMouseDown={startResize}
        onMouseMove={resize}
        onMouseUp={endResize}
        onTouchStart={startResize}
        onTouchMove={resize}
        onTouchEnd={endResize}
      >
        <Hide above="lg">
          <Flex
            align="center"
            height="20px"
            justify="center"
            style={{
              cursor: active ? 'grabbing' : 'grab',
              touchAction: 'none',
            }}
            padding="8px"
            w="100%"
          >
            <IconDragHandle rotate={!isFullHeight} />
          </Flex>
        </Hide>
        {!hideButtons ? (
          <Flex align="center" justify="space-between" padding="12px 14px" width="100%">
            <Flex gap="4px" width="100%">
              {availableViews.map(({ label, name, icon, iconActive }) => {
                const isSelected = selectedTool === name;

                return (
                  <VStack
                    as="button"
                    background={isSelected ? '#E2E8F0' : 'transparent'}
                    borderRadius="12px"
                    key={name}
                    onClick={() => handleToolChange(name)}
                    padding="4px 3px 8px 3px"
                    spacing="4px"
                    flex={1}
                  >
                    {selectedTool === name ? iconActive : icon}
                    <Text as="b" fontSize="9px" color="#000000" lineHeight="11px">
                      {label}
                    </Text>
                  </VStack>
                );
              })}
            </Flex>
          </Flex>
        ) : (
          customToolbarContent
        )}
      </Box>
      {children}
    </Box>
  );
}
