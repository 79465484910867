const IconMoreInfo = (props) => (
  <svg
    width="19px"
    height="19px"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M8.76676 7.4066V13.6248H10.2322V7.4066H8.76676Z" fill="#1C8D02" />
    <path
      d="M8.90441 6.29332C9.07173 6.44716 9.27145 6.52408 9.50355 6.52408C9.73835 6.52408 9.93807 6.44716 10.1027 6.29332C10.27 6.13679 10.3537 5.94922 10.3537 5.73061C10.3537 5.5093 10.27 5.32173 10.1027 5.1679C9.93807 5.01136 9.73835 4.9331 9.50355 4.9331C9.27145 4.9331 9.07173 5.01136 8.90441 5.1679C8.73708 5.32173 8.65341 5.5093 8.65341 5.73061C8.65341 5.94922 8.73708 6.13679 8.90441 6.29332Z"
      fill="#1C8D02"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.4375 9.50002C17.4375 13.8838 13.8838 17.4375 9.50002 17.4375C5.11625 17.4375 1.5625 13.8838 1.5625 9.50002C1.5625 5.11625 5.11625 1.5625 9.50002 1.5625C13.8838 1.5625 17.4375 5.11625 17.4375 9.50002ZM16.1075 9.50002C16.1075 13.1493 13.1493 16.1075 9.50002 16.1075C5.85079 16.1075 2.8925 13.1493 2.8925 9.50002C2.8925 5.85079 5.85079 2.8925 9.50002 2.8925C13.1493 2.8925 16.1075 5.85079 16.1075 9.50002Z"
      fill="#1C8D02"
    />
  </svg>
);

export default IconMoreInfo;
