import Button from '@/components/button';
import IconCloseModal from '@/components/icons/IconCloseModal';

import { H3 } from '@/components/typography/Headings';
import { HStack, Modal, ModalBody, ModalCloseButton, ModalContent, VStack } from '@chakra-ui/react';
import FilterContainer from './FilterContainer';
import CategoryFilter from './CategoryFilter';

import { useEffect, useState } from 'react';
import { Option, Template } from '@/components/types';

import SelectMenu from '@/components/select/SelectMenu';
import { getTemplates } from '@/api/templates';
import { getTemplateOptions } from '../../utils';

const CLOSE_BUTTON_SIZE = 24;

const CLOSE_BUTTON_OFFSET = 12;

interface ProductsPageFilters {
  templateId: string;
  categoryId: string;
}

interface MobileFilterModalProps {
  filters: ProductsPageFilters;
  onFiltersUpdate: (filters: ProductsPageFilters) => void;
  categoryOptions: Option<string>[];
  onClose: () => void;
}

const MobileFilterModal = ({
  categoryOptions,
  onClose,
  filters,
  onFiltersUpdate,
}: MobileFilterModalProps) => {
  const [selectedCategoryId, setSelectedCategoryId] = useState(filters?.categoryId);

  const [selectedTemplateId, setSelectedTemplateId] = useState(filters?.templateId);

  const [templates, setTemplates] = useState<Template[]>([]);

  useEffect(() => {
    getTemplates({
      isDefault: true,
      categoryId: selectedCategoryId,
    }).then((templates) => {
      setTemplates(templates);
    });
  }, [selectedCategoryId]);

  const handleApply = () => {
    onFiltersUpdate({
      categoryId: selectedCategoryId,
      templateId: selectedTemplateId,
    });
  };

  const templateOptions = getTemplateOptions(templates);

  return (
    <Modal isOpen={true} onClose={onClose} motionPreset="slideInBottom" scrollBehavior="inside">
      <ModalContent alignSelf="center" p={0} h="100dvh" maxH="100dvh" overflow="hidden">
        <ModalCloseButton
          top={`${CLOSE_BUTTON_OFFSET}px`}
          h={`${CLOSE_BUTTON_SIZE}px`}
          w={`${CLOSE_BUTTON_SIZE}px`}
          right={`${CLOSE_BUTTON_OFFSET}px`}
          zIndex={2}
          _focusVisible={{
            boxShadow: 'none',
          }}
          _focus={{
            bg: 'none',
          }}
        >
          <IconCloseModal color="#212529" />
        </ModalCloseButton>
        <ModalBody padding="16px" position="relative">
          <H3 mb="16px">Filters</H3>
          <VStack h="calc(100% - 110px)" overflow="auto" pb="20px" spacing="24px">
            <FilterContainer label="Category">
              <CategoryFilter
                categoryOptions={categoryOptions}
                selectedCategoryId={selectedCategoryId}
                onSelectedCategoryId={(categoryId) => {
                  setSelectedTemplateId(null);

                  setSelectedCategoryId(categoryId);
                }}
              />
            </FilterContainer>
            <FilterContainer label="Template">
              <SelectMenu
                options={templateOptions}
                selectedValue={selectedTemplateId}
                onUpdateSelectedValue={setSelectedTemplateId}
                isStandAlone
              />
            </FilterContainer>
          </VStack>
          <HStack bottom="20px" left="16px" right="16px" position="fixed" spacing="12px">
            <Button label="Apply" onClick={() => handleApply()} w="100%" />
            <Button secondary label="Cancel" onClick={onClose} w={{ base: '100%' }} />
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default MobileFilterModal;
