import { Box, Center, HStack, Image, Text } from '@chakra-ui/react';

import { Design } from '@/lib/types';

import { ReactNode, useEffect, useState } from 'react';
import { getDesignPreviewImage } from '@/lib';

import { useHistory, useLocation } from 'react-router-dom';
import LoadingSpinner from '@/components/ui/LoadingSpinner';
import Button from '@/components/button';
import IconCart from '@/components/icons/IconCart';
import { isCrocsTemplate } from '@/lib/utils/special-brands';
import { CrocsModal } from '@/components/modals/CrocsModal';
import { Category } from '@/components/types';
import { trackEvent } from '@/analytics';

const BORDER_RADIUS = 16;

type ProductCardContainerProps = {
  design: Design;
  children: ReactNode;
  categories: Category[];
};

const ProductCardContainer = ({ design, children, categories }: ProductCardContainerProps) => {
  const [isLoadingPreviewImage, setIsLoadingPreviewImage] = useState(true);
  const [designPreviewImage, setDesignPreviewImage] = useState<string>(null);

  const [isCrocsModalVisible, setIsCrocsModalVisible] = useState(false);

  const [isHovered, setIsHovered] = useState(false);

  const history = useHistory();

  const { search } = useLocation();

  const searchParams = new URLSearchParams(search);

  useEffect(() => {
    getDesignPreviewImage(design)
      .then(({ previewImage }) => {
        setDesignPreviewImage(previewImage);

        setIsLoadingPreviewImage(false);
      })
      .catch((e) => console.log('E', e));
  }, []);

  const handleGoToProductDetails = () => {
    const returnToParams = searchParams.get('page')
      ? encodeURIComponent(`${searchParams.toString()}&highlightedDesignId=${design.id}`)
      : null;

    trackEvent('product_click', {
      product_id: design.id,
      product_name: design.name,
      price: design.template.price,
      category: categories.find(({ id }) => id === design.template.categoryId)?.name,
      designer_name: `${design.user.firstName} ${design.user.lastName}`,
      page_url: window.location.href,
    });

    history.push(
      `/products/${design.id}${
        returnToParams ? `?returnTo=${location.pathname}?${returnToParams}` : ''
      }`
    );
  };

  return (
    <Box
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      position="relative"
    >
      <Center
        bg="#EFEFEF"
        borderRadius={BORDER_RADIUS}
        cursor="pointer"
        aspectRatio="1/1"
        mb="8px"
        w="100%"
        onClick={handleGoToProductDetails}
        overflow="hidden"
        position="relative"
      >
        {isLoadingPreviewImage || !designPreviewImage ? null : (
          <Image h="100%" w="100%" objectFit="contain" src={designPreviewImage} />
        )}
        {isLoadingPreviewImage ? (
          <Center
            position="absolute"
            w="100%"
            h="100%"
            bg={isLoadingPreviewImage ? 'transparent' : 'black'}
            opacity="0.5"
            top={0}
          >
            <LoadingSpinner />
          </Center>
        ) : null}
      </Center>
      {isHovered && isCrocsTemplate(design.template) ? (
        <Button onClick={() => setIsCrocsModalVisible(true)} w="100%">
          <HStack spacing="6px">
            <IconCart />
            <Text as="b" fontSize="sm">
              Shop on Crocs
            </Text>
          </HStack>
        </Button>
      ) : (
        children
      )}
      {isCrocsModalVisible && (
        <CrocsModal design={design} onClose={() => setIsCrocsModalVisible(false)} />
      )}
    </Box>
  );
};

export default ProductCardContainer;
