import { Icon } from '@chakra-ui/react';

const IconFilter = () => (
  <Icon
    width="18px"
    height="18px"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6.95077 18L10.9226 15.3333V11.0667L17.8734 2H0L6.95077 11.0667V18Z" fill="#212529" />
  </Icon>
);

export default IconFilter;
