import { Box, HStack, Text, VStack } from '@chakra-ui/react';

import Button from '@/components/button';
import { useHistory } from 'react-router-dom';
import { Design } from '@/lib';

import ModalContainer from '@/components/modals/ModalContainer';
import { isCrocsTemplate } from '../../../../../lib/utils/special-brands';
import ProductCard from '@/views/products/components/ProductCard';
import { useCategories } from '@/api/templates';
import { trackEvent } from '@/analytics';
import RoyaltyModule from '@/components/royalties/RoyaltyModule';
import StoryIcon from '../icons/StoryIcon';

type Props = {
  design: Design;
  onClose: () => void;
};

const IpPublishedModal = ({ design, onClose }: Props) => {
  const history = useHistory();

  const { data: categories = [] } = useCategories();

  const handleGoToMyIPs = () => history.push('/designs?isPublished=true');

  const trackSuccessPopupEvent = (actionTaken: string) =>
    trackEvent('success_popup', {
      action_taken: actionTaken,
      item_name: design.name,
      item_category: categories.find(({ id }) => id === design.template.categoryId)?.name,
    });

  return (
    <ModalContainer
      title="Your Design is published!"
      onClose={onClose}
      width={{ base: 'calc(100% - 20px)', md: 498 }}
    >
      <>
        {!isCrocsTemplate(design.template) && (
          <Text color="secondaryDarkGray.600" mb="21px" textStyle="body">
            You’ll earn 15% in royalties from each sale of your merch.
          </Text>
        )}
        <VStack w="100%">
          <Box maxW="185px" pt="10px">
            <ProductCard design={design} />
          </Box>
          <Box bg="borderLight" h="1px" my={{ base: '10px', md: '12px' }} w="100%" />
          <RoyaltyModule product={design} />
          <Box bg="borderLight" h="1px" my={{ base: '10px', md: '12px' }} w="100%" />
          <HStack alignSelf="flex-start" spacing="3px">
            <Text as="b" color="secondaryDarkGray.600" textTransform="uppercase" fontSize="xs">
              Powered by
            </Text>
            <StoryIcon />
          </HStack>
        </VStack>
        <HStack gap="9px" mt="29px" w={{ base: '100%', md: 'auto' }}>
          <Button
            onClick={() => {
              trackSuccessPopupEvent('view_product_details');

              onClose();
            }}
            w="100%"
          >
            Product details
          </Button>
          <Button
            onClick={() => {
              trackSuccessPopupEvent('view_my_ips');

              handleGoToMyIPs();
            }}
            secondary
            w="100%"
          >
            My Designs
          </Button>
        </HStack>
      </>
    </ModalContainer>
  );
};

export default IpPublishedModal;
