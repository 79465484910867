import { Center, CenterProps, Text } from '@chakra-ui/react';

interface BadgeProps extends CenterProps {
  itemsCount: number;
}

const Badge = ({ itemsCount, ...rest }: BadgeProps) => (
  <Center
    bg="brand.600"
    borderRadius={94}
    width={{ base: '18px', md: '21px' }}
    height={{ base: '18px', md: '21px' }}
    padding="5px 8px"
    position="absolute"
    right={{ base: 0, md: '-4px' }}
    top={{ base: '4px', md: '2px' }}
    {...rest}
  >
    <Text as="b" color="#FFFFFF" fontSize="9px">
      {itemsCount}
    </Text>
  </Center>
);

export default Badge;
