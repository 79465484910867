import { Box, Popover, PopoverBody, PopoverContent, PopoverTrigger } from '@chakra-ui/react';
import RoyaltyModule from './RoyaltyModule';
import { Design } from '@/lib';
import { ReactNode } from 'react';

interface RoyaltyModulePopoverProps {
  icon: ReactNode;
  product: Design;
}

const RoyaltyModulePopover = ({ icon, product }: RoyaltyModulePopoverProps) => (
  <Popover offset={[0, 0]} placement="top-start" trigger="hover">
    <PopoverTrigger>
      <Box cursor="pointer">{icon}</Box>
    </PopoverTrigger>
    <PopoverContent w="313px">
      <PopoverBody p="16px">
        <RoyaltyModule product={product} />
      </PopoverBody>
    </PopoverContent>
  </Popover>
);

export default RoyaltyModulePopover;
