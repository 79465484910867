import { isNumber } from 'lodash';

export const getFormattedAmount = (currency: string, amount: number) => {
  if (isNumber(amount)) {
    return `${amount.toLocaleString('en-US', {
      style: 'currency',
      currency: currency || 'USD',
      minimumFractionDigits: Math.ceil(amount % 1) * 2,
    })}`;
  }
};
