import { Icon } from '@chakra-ui/react';

export const IconLikeOutlineGray = () => (
  <Icon
    width="50px"
    height="50px"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.5" width="49" height="49" rx="11.5" fill="white" />
    <rect x="0.5" y="0.5" width="49" height="49" rx="11.5" stroke="#D3DEEA" />
    <path
      d="M25.0005 17.5611C23.9109 16.5592 22.4847 16.0031 21.0045 16.0031C20.221 16.0039 19.4454 16.1596 18.7224 16.4613C17.9994 16.763 17.3431 17.2047 16.7915 17.7611C14.4385 20.1241 14.4395 23.8201 16.7935 26.1731L24.1255 33.5051C24.2955 33.8041 24.6235 33.9971 25.0005 33.9971C25.1553 33.9955 25.3076 33.9578 25.4451 33.8867C25.5827 33.8157 25.7016 33.7134 25.7925 33.5881L33.2075 26.1731C35.5615 23.8191 35.5615 20.1241 33.2055 17.7571C32.6541 17.2018 31.9984 16.7609 31.276 16.4599C30.5537 16.1589 29.779 16.0037 28.9965 16.0031C27.5163 16.0033 26.0902 16.5593 25.0005 17.5611ZM31.7915 19.1711C33.3545 20.7421 33.3555 23.1961 31.7935 24.7591L25.0005 31.5521L18.2075 24.7591C16.6455 23.1961 16.6465 20.7421 18.2055 19.1751C18.9655 18.4191 19.9595 18.0031 21.0045 18.0031C22.0495 18.0031 23.0395 18.4191 23.7935 19.1731L24.2935 19.6731C24.3863 19.766 24.4965 19.8397 24.6178 19.89C24.7391 19.9404 24.8691 19.9663 25.0005 19.9663C25.1318 19.9663 25.2618 19.9404 25.3832 19.89C25.5045 19.8397 25.6147 19.766 25.7075 19.6731L26.2075 19.1731C27.7195 17.6641 30.2815 17.6681 31.7915 19.1711Z"
      fill="#212529"
    />
  </Icon>
);
