import axios from 'axios';

import { useQuery } from '@tanstack/react-query';

import { Brand } from '@/components/types';

const ENTITY = `brands`;

const getBrands = () => axios.get<Brand[]>(`/${ENTITY}`).then(({ data }) => data);

export const useBrands = () => {
  const { data: brands, isLoading } = useQuery([ENTITY], () => getBrands());

  return { brands, isLoading };
};

export const getBrand = (id: string) =>
  axios.get<Brand>(`/${ENTITY}/${id}`).then(({ data }) => data);

export const useBrand = (id: string) => {
  const { data: brand, isLoading } = useQuery([ENTITY, id], () => getBrand(id));

  return { brand, isLoading };
};
