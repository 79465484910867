import { User } from '@/components/types';

import { Flex, VStack } from '@chakra-ui/react';

import { H2 } from '@/components/typography/Headings';

import ProductList from './components/ProductList';
import FeaturedBrands from './components/FeaturedBrands';
import { useParams } from 'react-router-dom';
import BrandHeader from './components/BrandHeader';

interface ProductsListProps {
  me?: User;
  onDesignClicked: () => void;
}

const ProductsPage = ({ me, onDesignClicked }: ProductsListProps) => {
  const { id } = useParams<{ id: string }>();

  const isBrandPage = !!id;

  return (
    <Flex h="100%" bg="#FFFFFF" direction="column" overflow="auto">
      {isBrandPage ? (
        <BrandHeader brandId={id} onDesignClicked={onDesignClicked} />
      ) : (
        <VStack
          padding={{ base: '16px 0px 16px 16px', md: '24px 32px 8px 32px' }}
          align="flex-start"
        >
          <H2 mb={{ base: '8px', md: '24px' }}>Explore featured communities!</H2>
          <FeaturedBrands />
        </VStack>
      )}

      <ProductList user={me} brandId={id} />
    </Flex>
  );
};

export default ProductsPage;
