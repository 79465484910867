import { Box, Center, HStack, Text, useToast, VStack } from '@chakra-ui/react';

import { useHistory, useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { Cart, ShippingInfo } from '@/components/types';
import { getOrder, getOrderShipping } from '@/api/cart';
import AppContainer from '@/layouts/AppContainer';
import IconChevronRight from '@/components/icons/IconChevronRight';

import { H3 } from '@/components/typography/Headings';
import TrackingEventBox from './components/TrackingEventBox';

import TrackingInfoBox from './components/TrackingInfo';
import LoadingSpinner from '@/components/ui/LoadingSpinner';

export default function OrderTracking() {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const [order, setOrder] = useState<Cart>(null);

  const [shipping, setShipping] = useState<ShippingInfo>(null);
  const [isLoading, setIsLoading] = useState(true);

  const toast = useToast();

  const prepareOrder = useCallback(async () => {
    try {
      const resOrder = await getOrder(id);

      const resShipping = await getOrderShipping(resOrder.id);

      setShipping(resShipping);

      setOrder(resOrder);
    } catch {
      toast({ title: 'Error loading tracking info', status: 'error' });
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    prepareOrder();
  }, [prepareOrder]);

  const handleGoBackToHome = () => history.push(`/`);

  const handleGoBackToOrders = () => history.push(`/orders`);

  const handleGoBackToDetails = () => history.push(`/orders/${id}`);

  const { estimatedDeliveryAt, shopifyOrderNumber } = order || {};

  const { costs, trackingEvents = [], trackingInfo } = shipping || {};

  const allTrackingEvents = [
    ...trackingEvents,
    {
      description: 'Estimated Delivery',
      triggered_at: estimatedDeliveryAt,
    },
  ];

  return (
    <AppContainer contentContainerProps={{ bg: '#FFFFFF', padding: 0 }}>
      <Box bg="#FFFFFF" h="100%" p="24px 34px">
        <HStack gap="4px" mb="34px">
          <Text color="brand.600" onClick={handleGoBackToHome} textStyle="breadcrumb">
            Home
          </Text>
          <IconChevronRight />
          <Text color="brand.600" textStyle="breadcrumb" onClick={handleGoBackToOrders}>
            Orders
          </Text>
          <IconChevronRight />
          <Text
            color="secondaryDarkGray.600"
            fontWeight={400}
            textStyle="breadcrumb"
            onClick={handleGoBackToDetails}
          >
            Order Details
          </Text>
          <IconChevronRight />
          <Text color="secondaryDarkGray.600" fontWeight={400} textStyle="breadcrumb">
            Order tracking
          </Text>
          <Text color="secondaryDarkyGray.600"></Text>
        </HStack>
        <H3 mb="24px">Order Tracking</H3>
        {isLoading ? (
          <Center bg="transparent" h="100%">
            <LoadingSpinner />
          </Center>
        ) : (
          <HStack alignItems="flex-start" gap="100px">
            <VStack align="flex-start" gap={0} pb="120px">
              {allTrackingEvents.map((event, index) => (
                <TrackingEventBox
                  isLastEvent={index === allTrackingEvents.length - 2}
                  isEstimatedDelivery={index === allTrackingEvents.length - 1}
                  key={index}
                  trackingEvent={event}
                />
              ))}
            </VStack>
            <TrackingInfoBox
              currency={costs?.currency}
              shippingPrice={parseInt(costs?.total)}
              orderNumber={shopifyOrderNumber}
              trackingInfo={trackingInfo}
            />
          </HStack>
        )}
      </Box>
    </AppContainer>
  );
}
