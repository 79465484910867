import { Icon } from '@chakra-ui/react';

const IconShare = () => (
  <Icon
    width={{ base: '50px', md: '50px' }}
    height={{ base: '50px', md: '50px' }}
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.5" width="49" height="49" rx="11.5" fill="white" />
    <rect x="0.5" y="0.5" width="49" height="49" rx="11.5" stroke="#D3DEEA" />
    <path
      d="M34.707 24.293L26.707 16.293C26.5672 16.1532 26.389 16.0579 26.195 16.0193C26.0011 15.9808 25.8 16.0005 25.6173 16.0762C25.4346 16.1519 25.2784 16.2801 25.1685 16.4445C25.0586 16.6089 25 16.8023 25 17V20.545C22.2683 20.7978 19.7293 22.0609 17.8797 24.0871C16.0302 26.1132 15.0033 28.7567 15 31.5V33C15 33.2077 15.0646 33.4102 15.185 33.5795C15.3053 33.7488 15.4753 33.8764 15.6715 33.9446C15.8676 34.0128 16.0801 34.0183 16.2795 33.9602C16.4789 33.9022 16.6553 33.7835 16.7842 33.6206C17.7638 32.4556 18.9658 31.4974 20.32 30.8022C21.6741 30.1069 23.1532 29.6885 24.6709 29.5713C24.7207 29.565 24.8457 29.5552 25 29.5454V33C25 33.1978 25.0586 33.3911 25.1685 33.5556C25.2784 33.72 25.4346 33.8482 25.6173 33.9238C25.8 33.9995 26.0011 34.0193 26.195 33.9807C26.389 33.9421 26.5672 33.8469 26.707 33.707L34.707 25.707C34.7999 25.6142 34.8735 25.504 34.9238 25.3827C34.9741 25.2614 34.9999 25.1313 34.9999 25C34.9999 24.8687 34.9741 24.7387 34.9238 24.6174C34.8735 24.4961 34.7999 24.3859 34.707 24.293ZM27 30.586V28.5C27 28.3687 26.9742 28.2387 26.9239 28.1173C26.8737 27.996 26.8 27.8857 26.7072 27.7929C26.6143 27.7 26.5041 27.6263 26.3827 27.5761C26.2614 27.5258 26.1313 27.5 26 27.5C25.7451 27.5 24.7041 27.5494 24.4385 27.585C21.7431 27.8333 19.1774 28.8573 17.0518 30.5332C17.2929 28.3276 18.3392 26.2884 19.9903 24.8061C21.6414 23.3239 23.7812 22.5028 26 22.5C26.1313 22.5001 26.2614 22.4742 26.3827 22.424C26.5041 22.3737 26.6143 22.3001 26.7072 22.2072C26.8 22.1144 26.8737 22.0041 26.9239 21.8828C26.9742 21.7614 27 21.6314 27 21.5V19.4141L32.5859 25L27 30.586Z"
      fill="#212529"
    />
  </Icon>
);

export default IconShare;
