import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Image,
  Menu,
  MenuButton,
  MenuList,
  Text,
  VStack,
} from '@chakra-ui/react';

import IconChevronDown from '@/components/icons/IconChevronDown';

import IconAll from './icons/IconAll';
import { isEmpty } from 'lodash';
import MultiSelectMenu, { MultiSelectMenuProps } from './MultiSelectMenu';

const BUTTON_SIZE = 38;

export interface MultiSelectProps<T> extends MultiSelectMenuProps<T> {
  label: string;
  onClear?: () => void;
}

const MultiSelect = function <T>({
  label,
  width,
  options,
  onClear,
  selectedValues = [],
  ...rest
}: MultiSelectProps<T>) {
  const selectedOption = options.find(({ id }) => id === selectedValues[0]);

  const { iconUrl, id, name } = selectedOption || {};

  return (
    <Menu closeOnSelect={true}>
      {({ isOpen }) => (
        <>
          <Box position="relative">
            <MenuButton
              bg="transparent"
              border="1px solid"
              borderColor="borderLight"
              borderRadius="6px"
              color="black.500"
              height="46px"
              py="4px"
              px="4px"
              width={width}
              _hover={{
                borderColor: 'black.500',
              }}
            >
              <Flex w="100%" alignItems="center" justifyContent="space-between">
                <Flex
                  w="100%"
                  alignItems="center"
                  justifyContent="space-between"
                  position="relative"
                >
                  <HStack fontWeight={400} justify="start" spacing="10px">
                    {!id ? (
                      <Center
                        bg="#F0F5FF"
                        borderRadius={4}
                        h={`${BUTTON_SIZE}px`}
                        w={`${BUTTON_SIZE}px`}
                      >
                        <IconAll />
                      </Center>
                    ) : (
                      <Image h={`${BUTTON_SIZE}px`} w={`${BUTTON_SIZE}px`} src={iconUrl} />
                    )}
                    <VStack align="flex-start" spacing={0}>
                      <Text color="secondaryDarkGray.600" textStyle="bodySuperSmall">
                        {label}
                      </Text>
                      <Text textStyle="bodySmall">{selectedOption ? `${name}` : 'All'}</Text>
                    </VStack>
                  </HStack>
                </Flex>
                <HStack w="40px">
                  <Box borderLeftColor="borderLight" borderLeftWidth={1} h="24px" />
                  <IconChevronDown color="#212529" transform={isOpen ? 'rotate(180deg)' : 'none'} />
                </HStack>
              </Flex>
            </MenuButton>
            {!isEmpty(selectedValues) && onClear ? (
              <Button
                color="secondaryDarkGray.600"
                position="absolute"
                h="16px"
                right="48px"
                top="15px"
                fontSize="xs"
                onClick={(e) => {
                  e.stopPropagation();

                  onClear();
                }}
                variant="link"
              >
                Clear
              </Button>
            ) : null}
          </Box>
          <MenuList
            boxShadow="0px 10px 16px -4px rgba(11, 31, 67, 0.20)"
            p="12px"
            borderRadius="6px"
            bg="#FFFFFF"
            border="1px solid"
            borderColor="gray.100"
            maxH="250px"
            overflow="auto"
            width={width}
            zIndex={99}
          >
            <MultiSelectMenu
              options={options}
              selectedValues={selectedValues}
              width={width}
              {...rest}
            />
          </MenuList>
        </>
      )}
    </Menu>
  );
};

export default MultiSelect;
