import Badge from '@/components/badge';
import IconFilter from '@/components/icons/IconFilter';
import { Box, IconButton } from '@chakra-ui/react';

interface ButtonToggleFiltersProps {
  isVisible: boolean;
  onToggleVisible: (isVisible: boolean) => void;
  count: number;
}

const ButtonToggleFilters = ({ isVisible, onToggleVisible, count }: ButtonToggleFiltersProps) => (
  <Box position="relative">
    <IconButton
      borderColor={isVisible ? 'black.500' : 'gray.100'}
      variant="secondary"
      aria-label="Filter"
      icon={<IconFilter />}
      onClick={() => onToggleVisible(!isVisible)}
      _focus={{
        borderColor: 'black.500',
      }}
    ></IconButton>
    {count ? (
      <Badge
        itemsCount={1}
        right={{ base: '-8px', md: '-8px' }}
        top={{ base: '-8px', md: '-8px' }}
      />
    ) : null}
  </Box>
);

export default ButtonToggleFilters;
