import MultiSelect, { MultiSelectProps } from './MultiSelect';
import { isEmpty } from 'lodash';

export interface SelectProps<T>
  extends Omit<MultiSelectProps<T>, 'onUpdateSelectedValues' | 'selectedValues'> {
  selectedValue: T;
  onUpdateSelectedValue: (values: T) => void;
}

const Select = function <T>({ selectedValue, onUpdateSelectedValue, ...rest }: SelectProps<T>) {
  const handleUpdateSelectedValues = (values) => {
    if (isEmpty(values)) {
      onUpdateSelectedValue(selectedValue);
    } else {
      const newValue = values.filter((value) => selectedValue !== value);

      onUpdateSelectedValue(newValue[0]);
    }
  };

  return (
    <MultiSelect
      onUpdateSelectedValues={handleUpdateSelectedValues}
      selectedValues={[selectedValue]}
      {...rest}
    />
  );
};

export default Select;
