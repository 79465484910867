import { Icon } from '@chakra-ui/react';

const IconCheckmark = () => (
  <Icon
    width="16px"
    height="16px"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.1089 4.94423L6.72592 12.3272L1.89062 7.49191L2.73915 6.64338L6.72592 10.6301L13.2604 4.0957L14.1089 4.94423Z"
      fill="#064AC4"
    />
  </Icon>
);

export default IconCheckmark;
