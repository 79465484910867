import { Icon } from '@chakra-ui/react';

export const IconLikeGray = () => (
  <Icon
    width="50px"
    height="50px"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.5" width="49" height="49" rx="11.5" fill="white" />
    <rect x="0.5" y="0.5" width="49" height="49" rx="11.5" stroke="#D3DEEA" />
    <path
      d="M25.0005 18.1549C24.1546 17.1518 22.5271 16.1886 21.0718 16.1886C20.3015 16.1894 19.539 16.3425 18.8282 16.6392C18.1173 16.9358 17.4721 17.3701 16.9297 17.917C14.6164 20.2402 14.6173 23.874 16.9317 26.1873L24.1402 33.3959C24.3074 33.6898 24.6298 33.8796 25.0005 33.8796C25.1527 33.8781 25.3024 33.841 25.4376 33.7711C25.5729 33.7013 25.6898 33.6007 25.7792 33.4775L33.0693 26.1873C35.3837 23.873 35.3837 20.2402 33.0673 17.9131C32.5252 17.3671 31.8806 16.9337 31.1704 16.6378C30.4602 16.3418 29.6986 16.1892 28.9292 16.1886C27.474 16.1888 25.6956 17.1518 25.0005 18.1549ZM31.6771 19.3033C33.2138 20.8478 33.2148 23.2605 31.6791 24.7972L25.0005 31.4758L18.3219 24.7972C16.7862 23.2605 16.7872 20.8478 18.3199 19.3072C19.0671 18.5639 20.0444 18.1549 21.0718 18.1549C22.0992 18.1549 23.0725 18.5639 23.8138 19.3052L24.3054 19.7968C24.3966 19.8882 24.505 19.9607 24.6242 20.0101C24.7435 20.0596 24.8714 20.0851 25.0005 20.0851C25.1296 20.0851 25.2575 20.0596 25.3767 20.0101C25.496 19.9607 25.6044 19.8882 25.6956 19.7968L26.1872 19.3052C27.6737 17.8216 30.1926 17.8256 31.6771 19.3033Z"
      fill="#D3DEEA"
    />
    <path
      d="M24.6116 18.9102C24.1952 19.0489 23.316 18.1236 20.5396 17.1518C20.1077 17.4449 19.1144 18.1236 18.5961 18.4938C17.9483 18.9565 17.0228 20.0671 16.6064 21.2239C16.2732 22.1494 17.1154 24.4168 17.5781 25.4348L24.9819 32.3295C26.3701 31.0339 29.3501 28.2667 30.1645 27.5634C31.1825 26.6842 32.9409 24.7407 33.5424 23.3062C34.144 21.8717 33.2648 22.1494 33.1723 21.2239C33.0797 20.2984 33.0797 20.6686 32.9409 19.6506C32.8021 18.6326 31.4601 18.2624 30.7198 18.031C29.9794 17.7997 29.4241 17.6146 27.712 17.7534C25.9999 17.8922 25.0281 18.7714 24.6116 18.9102Z"
      fill="#D3DEEA"
    />
  </Icon>
);
