import { Icon } from '@chakra-ui/react';

const IconMoreInfo = () => (
  <Icon
    width="18px"
    height="18px"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8.30556 7.0165V12.9074H9.69391V7.0165H8.30556Z" fill="#A0AEC0" />
    <path
      d="M8.43596 5.96181C8.59448 6.10755 8.78369 6.18042 9.00357 6.18042C9.22602 6.18042 9.41522 6.10755 9.57119 5.96181C9.72971 5.81352 9.80897 5.63582 9.80897 5.42872C9.80897 5.21906 9.72971 5.04136 9.57119 4.89562C9.41522 4.74732 9.22602 4.67318 9.00357 4.67318C8.78369 4.67318 8.59448 4.74732 8.43596 4.89562C8.27744 5.04136 8.19817 5.21906 8.19817 5.42872C8.19817 5.63582 8.27744 5.81352 8.43596 5.96181Z"
      fill="#A0AEC0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.52 8.99974C16.52 13.1528 13.1533 16.5195 9.00023 16.5195C4.84718 16.5195 1.48047 13.1528 1.48047 8.99974C1.48047 4.84669 4.84718 1.47998 9.00023 1.47998C13.1533 1.47998 16.52 4.84669 16.52 8.99974ZM15.26 8.99974C15.26 12.4569 12.4574 15.2595 9.00023 15.2595C5.54306 15.2595 2.74047 12.4569 2.74047 8.99974C2.74047 5.54257 5.54306 2.73998 9.00023 2.73998C12.4574 2.73998 15.26 5.54257 15.26 8.99974Z"
      fill="#A0AEC0"
    />
  </Icon>
);

export default IconMoreInfo;
