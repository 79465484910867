import { useBrands } from '@/api/brands';
import { HStack, Image, Text, useBreakpointValue, VStack } from '@chakra-ui/react';
import LoadingSpinner from '../../../components/ui/LoadingSpinner';
import { H4 } from '../../../components/typography/Headings';
import { useHistory } from 'react-router-dom';

export default function FeaturedBrands() {
  const { brands, isLoading } = useBrands();
  const isMobile = useBreakpointValue({ base: true, md: false });
  const history = useHistory();
  const ICON_SIZE = isMobile ? '40px' : '60px';

  if (isLoading || !brands) return <LoadingSpinner />;

  return (
    <HStack
      overflowX="auto"
      spacing={4}
      w="100%"
      css={{
        '&::-webkit-scrollbar': { display: 'none' },
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
      }}
    >
      {brands
        .filter((brand) => brand.isFeatured)
        .map((brand) => (
          <VStack
            key={brand.id}
            bgImage={`linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.7) 100%), url(${brand.cardImageUrl})`}
            bgSize="cover"
            bgPosition="center"
            w={{ base: '236px', md: '382px' }}
            h={{ base: '140px', md: '160px' }}
            align="left"
            borderRadius={8}
            py={{ base: '11px', md: '15px' }}
            px={{ base: '11px', md: '17px' }}
            flexShrink={0}
            cursor="pointer"
            onClick={() => history.push(`/brands/${brand.id}`)}
          >
            <Image src={brand.iconUrl} alt={brand.name} w={ICON_SIZE} h={ICON_SIZE} />
            <VStack align="left" mt="auto" spacing={{ base: '0px', md: '4px' }}>
              <H4 color="white">{brand.name}</H4>
              <Text color="white" textStyle="body-sm-regular">
                {`Products: ${brand.designsCount}`}
              </Text>
            </VStack>
          </VStack>
        ))}
    </HStack>
  );
}
