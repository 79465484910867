import { pad } from '@/utils/time';
import { Box, Center, HStack, Text, VStack } from '@chakra-ui/react';
import { times } from 'lodash';

interface ScrollIndicatorProps {
  activeIndex: number;
  numberOfItems: number;
}

const ScrollIndicator = ({ activeIndex, numberOfItems }: ScrollIndicatorProps) => (
  <VStack align="flex-start" spacing="12px">
    {times(numberOfItems, (index) => {
      const isActive = activeIndex === index;

      return (
        <HStack spacing="2px" key={index}>
          <Center key={index} h="16px" w="23px">
            <Text
              color={isActive ? 'secondaryDarkGray.600' : 'secondaryGray.400'}
              textStyle="bodySuperSmall"
            >
              {pad(index + 1)}
            </Text>
          </Center>
          {isActive ? <Box bg="secondaryDarkGray.600" h="1.3px" w="20px" /> : null}
        </HStack>
      );
    })}
  </VStack>
);

export default ScrollIndicator;
