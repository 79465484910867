import { Button, useBreakpointValue } from '@chakra-ui/react';

import IconCart from '@/components/icons/IconCart';

import { useHistory, useLocation } from 'react-router-dom';
import Badge from '../badge';

const CartIndicator = ({ cartItemsCount }: { cartItemsCount: number }) => {
  const history = useHistory();

  const isMobile = useBreakpointValue({ base: true, md: false });

  const { search } = useLocation();

  const searchParams = new URLSearchParams(search);

  const isCartDrawerVisible = searchParams.get('showCart');

  const handleGoToCart = (e) => {
    e.preventDefault();

    if (isMobile) {
      history.push('/cart');

      return;
    }

    history.replace(isCartDrawerVisible ? `?showCart` : '?showCart=true');
  };

  return (
    <Button bg="transparent" onClick={handleGoToCart} position="relative" padding="0">
      <IconCart
        color="#212529"
        height={{ base: '28px', md: '32px' }}
        width={{ base: '28px', md: '32px' }}
      />
      <Badge itemsCount={cartItemsCount} />
    </Button>
  );
};

export default CartIndicator;
