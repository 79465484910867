import AppContainer from '@/layouts/AppContainer';

import { useMe } from '@/api/auth';
import ProductsPage from './ProductsPage';
import { useHistory } from 'react-router-dom';

const ProductsPageAuthenticated = () => {
  const { data: me } = useMe();
  const history = useHistory();

  const onDesignClicked = (templateId?: string) => {
    if (templateId) {
      history.push(`/designs/draft?templateId=${templateId}`);
    } else {
      history.push('/designs/new');
    }
  };

  return (
    <AppContainer contentContainerProps={{ padding: 0 }}>
      <ProductsPage me={me} onDesignClicked={onDesignClicked} />
    </AppContainer>
  );
};

export default ProductsPageAuthenticated;
