import IconCheckmark from '@/components/select/icons/IconCheckmark';
import { Option } from '@/components/types';
import { Box, Flex, HStack, Text, VStack } from '@chakra-ui/react';

const HIGHLIGHT_COLOR = 'lightGray.400';

interface CategoryFilterProps {
  categoryOptions: Option<string>[];
  selectedCategoryId: string;
  onSelectedCategoryId: (id: string) => void;
}

const CategoryFilter = ({
  categoryOptions,
  selectedCategoryId,
  onSelectedCategoryId,
}: CategoryFilterProps) => (
  <VStack align="flex-start" spacing={0} w="100%">
    {categoryOptions.map(({ id, name }, index) => {
      const isSelected = selectedCategoryId === id;

      return (
        <Box
          as="button"
          key={index}
          borderRadius="4px"
          fontWeight={isSelected ? 600 : 500}
          mb="6px"
          bg={isSelected ? HIGHLIGHT_COLOR : 'transparent'}
          onClick={() => onSelectedCategoryId(id)}
          _hover={{ bg: HIGHLIGHT_COLOR }}
          _focus={{ bg: HIGHLIGHT_COLOR }}
          p="12px"
          w="100%"
        >
          <Flex align="center" justify="space-between" w="100%">
            <HStack>
              <Text color={isSelected ? 'brand.600' : 'black.500'} textStyle="subtitle">
                {name}
              </Text>
            </HStack>
            {isSelected ? <IconCheckmark /> : null}
          </Flex>
        </Box>
      );
    })}
  </VStack>
);

export default CategoryFilter;
