import { Design } from '@/lib';
import { HStack, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { getFormattedAmount } from '../utils/currency-formatter';
import IconMoreInfo from './icons/IconMoreInfo';
import RoyaltyModulePopover from '@/components/royalties/RoyaltyModulePopover';
import { useCurrencies } from '@/api/currencies';
import { keyBy } from 'lodash';
import { Currency } from '@/components/types';
import DesignTableThumbnail from './DesignTableThumbnail';
import { Link } from 'react-router-dom';

const COLUMNS = [
  {
    name: 'IP',
    getValue: (design: Design) => <DesignTableThumbnail design={design} />,
    width: 54,
  },
  {
    name: 'Name',
    getContent: (design: Design) => <Link to={`/products/${design.id}`}>{design.name}</Link>,
  },
  {
    name: 'Price',
    getValue: (design: Design, currency: Currency) => {
      const { template } = design;

      const { price } = template;

      return getFormattedAmount(currency?.name, price);
    },
  },
  {
    name: 'Earnings per sale',
    getValue: (design: Design, currency: Currency) => {
      const { template } = design;

      const { price, royaltyPercentage } = template;

      return (
        <HStack gap="5px">
          <Text textStyle="bodySmall">
            {getFormattedAmount(currency?.name, price * (royaltyPercentage / 100))}
          </Text>
          <RoyaltyModulePopover
            icon={<IconMoreInfo />}
            product={{
              ...design,
              template: { ...template, currency },
            }}
          />
        </HStack>
      );
    },
  },
  {
    name: 'Total sales',
    getValue: (design: Design) => design.numPurchases,
  },
  {
    name: 'Total earnings',
    getValue: (design: Design, currency: Currency) =>
      getFormattedAmount(currency?.name, design.totalEarnings),
  },
];

interface IPsTableProps {
  designs: Design[];
}

const IPsTable = ({ designs }: IPsTableProps) => {
  const { data: currencies } = useCurrencies();

  const currenciesById = keyBy(currencies, 'id');

  return (
    <TableContainer>
      <Table sx={{ tableLayout: 'fixed' }} variant="unstyled">
        <Thead>
          <Tr borderBottom="1px solid" borderBottomColor="borderLight">
            {COLUMNS.map(({ name, width }) => {
              return (
                <Th
                  cursor="pointer"
                  fontWeight={400}
                  key={name}
                  p="6px 0"
                  textTransform="none"
                  color="secondaryDarkGray.600"
                  fontFamily="inherit"
                  width={width}
                >
                  <Text color="secondaryDarkGray.600" fontSize="xs">
                    {name}
                  </Text>
                </Th>
              );
            })}
          </Tr>
        </Thead>
        <Tbody>
          {designs.map((design, index) => {
            const {
              template: { currencyId },
            } = design;

            const currency = currenciesById[currencyId];

            return (
              <Tr key={index} borderBottom="1px solid" borderBottomColor="borderLight">
                {COLUMNS.map(({ name, getValue, getContent, width }) => (
                  <Td fontSize="sm" h="52px" key={name} p="6px 0" width={width}>
                    {getValue ? (
                      <Text textStyle="bodySmall">{getValue(design, currency)}</Text>
                    ) : null}
                    {getContent ? getContent(design) : null}
                  </Td>
                ))}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default IPsTable;
