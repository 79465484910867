import { Design } from '@/lib/types';
import ImageThumbnail, { ImageThumbnailProps } from './ImageThumbnail';

interface DesignSideImageProps extends Omit<ImageThumbnailProps, 'url'> {
  design: Design;
  sideName: string;
}

const DesignSideImage = ({ design, sideName, ...rest }: DesignSideImageProps) => {
  const { sides = [] } = design;

  const designSide = sides.find(({ templateSide }) => templateSide.name === sideName) || {};

  const { previewImage } = designSide;

  return <ImageThumbnail url={previewImage} {...rest} />;
};

export default DesignSideImage;
