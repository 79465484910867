import { getOrders } from '@/api/cart';
import { getDesign } from '@/api/designs';
import { H2 } from '@/components/typography/Headings';
import AppContainer from '@/layouts/AppContainer';
import {
  Box,
  Center,
  Flex,
  HStack,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import OrderSummaryCard from './components/OrderSummaryCard';
import { isEmpty, uniq } from 'lodash';
import NoOrdersEmptyState from './components/NoOrdersEmptyState';
import { Cart, ORDER_STATUS } from '@/components/types';
import IconChevronRight from '@/components/icons/IconChevronRight';
import { useHistory } from 'react-router-dom';
import { getStatusLabel } from './utils';

const getStatuses = (orders: Cart[]) => {
  const statuses = uniq(orders.map(({ orderStatus: { text } }) => text)).map((value) => ({
    name: getStatusLabel(value),
    value,
  }));

  return [
    {
      name: 'All',
      value: null,
    },
    ...statuses,
  ];
};

const Orders = () => {
  const [orders, setOrders] = useState<Cart[]>([]);
  const [isLoading, setLoading] = useState(true);

  const history = useHistory();

  const prepareOrders = useCallback(async () => {
    const resOrders = await getOrders();

    const promises = [];

    for (const order of resOrders) {
      const designsPromise = Promise.all(
        order.items.map((item) =>
          getDesign(item.designId).catch(() => {
            return null;
          })
        )
      ).then((designs) => {
        order.designs = designs;

        order.totalPrice = order.items.reduce((result, cartItem, index) => {
          const { quantity } = cartItem;

          const design = designs[index];

          return result + (design?.template?.price || 0) * quantity;
        }, 0);
      });
      promises.push(designsPromise);
    }

    await Promise.all(promises);

    resOrders.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

    setOrders(resOrders);
    setLoading(false);
  }, []);

  useEffect(() => {
    prepareOrders();
  }, [prepareOrders]);

  const handleCanceledOrder = (id: string) => {
    const newOrders = orders.map((order) => {
      if (order.id === id) {
        return { ...order, orderStatus: { ...order.orderStatus, text: ORDER_STATUS.REFUNDED } };
      }

      return order;
    });

    setOrders(newOrders);
  };

  const statuses = getStatuses(orders);

  return (
    <AppContainer contentContainerProps={{ bg: '#FFFFFF', padding: 0 }}>
      <Flex direction="column" w="100%" h="100%" p="24px 32px">
        <HStack gap="4px" mb="26px">
          <Text color="brand.600" onClick={() => history.push('/')} textStyle="breadcrumb">
            Home
          </Text>
          <IconChevronRight />
          <Text color="secondaryDarkGray.600" fontWeight={400} textStyle="breadcrumb">
            Orders
          </Text>
        </HStack>
        <H2 mb="26px">Orders</H2>
        {isLoading ? (
          <Center bg="#FFFFFF" h="400px">
            <Spinner thickness="1px" speed="0.65s" emptyColor="gray" size="md" />
          </Center>
        ) : isEmpty(orders) ? (
          <NoOrdersEmptyState />
        ) : (
          <Tabs display="flex" flexDirection="column" flex={1} variant="unstyled">
            <Box overflow="auto">
              <TabList>
                {statuses.map((category) => (
                  <Tab key={category.value}>{category.name}</Tab>
                ))}
              </TabList>
            </Box>
            <TabPanels flex={1} pt="31px">
              {statuses.map((category) => {
                const ordersForCategory = orders.filter(
                  (order) => !category.value || order.orderStatus.text === category.value
                );

                return (
                  <TabPanel
                    h="100%"
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                    padding="0"
                    key={category.value}
                  >
                    <VStack spacing="33px">
                      {ordersForCategory.map((order, index) => {
                        return (
                          <OrderSummaryCard
                            key={index}
                            order={order}
                            onCanceledOrder={handleCanceledOrder}
                          />
                        );
                      })}
                    </VStack>
                  </TabPanel>
                );
              })}
            </TabPanels>
          </Tabs>
        )}
      </Flex>
    </AppContainer>
  );
};

export default Orders;
