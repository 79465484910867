import {
  Design,
  Template as TemplateBase,
  TemplateColor as TemplateColorBase,
  TemplateColorImage as TemplateColorImageBase,
  TemplateColorSize as TemplateColorSizeBase,
  TemplateSide as TemplateSideBase,
  User as UserBase,
} from '@/lib/types';
import { ReactNode } from 'react';
import Stripe from 'stripe';

export interface Route {
  name: string;
  defaultPath?: string;
  path?: string;
  layout?: string;
  notInMenu?: boolean;
  icon?: ReactNode;
  iconActive?: ReactNode;
  routes?: Route[];
}

export interface Option<T> {
  iconUrl?: string;
  name: string;
  id: T;
  description?: string;
}

export interface UserRole {
  id: number;
  name: string;
}

export interface ApiKey {
  numCalls: number;
  id: string;
  key: string;
  lastUsedAt: Date;
  isActive: boolean;
  isDeleted: boolean;
  createdAt: Date;
}
export interface Client {
  address: string;
  credits: number;
  customStylesPurchasedCount: number;
  name: string;
  id: string;
  domain: string;
  apiKeys: ApiKey[];
  subscriptionStatus: Stripe.Subscription.Status | 'in_house';
  stripeCustomerId: string;
}

export interface User extends UserBase {
  id: string;
  name: string;
  email: string;
  firstName: string;
  lastName: string;
  roles: UserRole[];
  avatarUrl: string;
  avatarBase64?: string;
  client: Client;
  invitedBy?: User;
  cart: Cart;
}

export interface Invite {
  email: string;
  id: string;
  invitedBy: User;
}

export interface TemplateColorImage extends TemplateColorImageBase {
  templateColorId?: string;
  image: string;
}

export interface TemplateColor extends Omit<TemplateColorBase, 'images'> {
  isDirty?: string;
  images?:
    | {
        [key: string]: string | TemplateColorImage;
      }
    | TemplateColorImage[];
}

export interface TemplateSize {
  id: number;
  name: string;
}

export interface TemplateSide extends TemplateSideBase {
  hasArea?: boolean;
  image?: string;
  isDeleted?: string;
  isDirty?: string;
  unit?: string;
  type?: string;
}

export interface Brand {
  bannerImageUrl: string;
  cardImageUrl: string;
  defaultTemplateId: string;
  description: string;
  designsCount: number;
  iconUrl: string;
  isFeatured: boolean;
  logoUrl: string;
  name: string;
  id: string;
  websiteUrl: string;
}

export interface Template extends Omit<TemplateBase, 'colors'> {
  brands?: Brand[];
  collectionDescription?: string;
  cta?: string;
  currencyId?: number;
  isDeleted?: boolean;
  numCopies?: number;
  numRemixes?: number;
  numLikes?: number;
  sides?: TemplateSide[];
  colors?: TemplateColor[];
  manufacturingProductId?: string;
  isDefault?: boolean;
  user?: UserBase;
  pinnedStyleIds?: string[];
  collectionBannerUrl?: string;
  collectionBannerUrlWide?: string;
  collectionBannerUrlMobile?: string;
  previewImageUrl?: string;
  iconUrl?: string;
}

export interface Category {
  id: string;
  name: string;
  order: number;
}

export interface Currency {
  id: number;
  name: string;
}

export interface Size {
  id: number;
  name: string;
}

export interface TemplateFilters {
  type?: string[];
  color?: string[];
  size?: number[];
}

export enum BillableActionType {
  IMAGE_MAKER = 'Image Maker',
  PHOTO_TRANSFORMER = 'Photo Transformer',
  FONTMAKER = 'Fontmaker',
  BACKGROUND_REMOVAL = 'Background Removal',
  IMAGE_UPSCALING = 'Image Upscaling',
  CUSTOM_ART_STYLE = 'Custom Style',
  INPAINT = 'Inpainting',
}

export interface DesignLike {
  id: string;
  designId: string;
  templateId: string;
}

export interface TemplateColorSize extends TemplateColorSizeBase {
  name?: string;
  size?: {
    name: string;
  };
}

export interface CartItem {
  id?: string;
  designId: string;
  price?: number;
  templateColorSizeId: string;
  templateColorSize?: TemplateColorSize;
  quantity: number;
  storefrontId: string;
}

export interface Cart {
  createdAt: string;
  designs: Design[];
  estimatedDeliveryAt: string;
  id: string;
  items: CartItem[];
  manufacturingOrderId: string;
  orderStatus: OrderStatus;
  shippingMethod: string;
  shopifyOrderId: string;
  shopifyOrderNumber: number;
  status: string;
  totalPrice: number;
  userId: string;
}

export interface Payment {
  amount: {
    amount?: string;
    currencyCode?: string;
  };
  billingAddress: {
    address1: string;
    address2?: string;
    city: string;
    country?: string;
    company?: string;
    countryCodeV2?: string;
    countryName?: string;
    firstName?: string;
    formatted?: string[];
    formattedArea?: string;
    lastName?: string;
    latitude?: number;
    longitude?: number;
    name: string;
    phone?: string;
    province?: string;
    provinceCode?: string;
    zip: string;
  };
  paymentDetails: {
    company?: string;
    number?: string;
    paymentMethodName?: string;
    wallet?: string;
  };
  paymentIcon: {
    altText?: string;
    thumbhash?: string;
    url: string;
  };
}

export enum ORDER_STATUS {
  ACTIVE = 'ACTIVE',
  CREATED = 'CREATED',
  DRAFT = 'DRAFT',
  FAILED = 'FAILED',
  PENDING = 'PENDING',
  CANCELED = 'CANCELED',
  REFUND_IN_PROGRESS = 'REFUND IN PROGRESS',
  REFUNDED = 'REFUNDED',
  ON_HOLD = 'ON HOLD',
  PROCESSING = 'PROCESSING',
  PARTIAL = 'PARTIAL',
  FULFILLED = 'FULFILLED',
}

export interface OrderStatus {
  id: string;
  text: ORDER_STATUS;
  borderColor: string;
  color: string;
  descriptionLong: string;
  descriptionShort: string;
  iconUrl: string;
}

export interface TrackingEvent {
  description: string;
  triggered_at: string;
}

export interface TrackingInfo {
  service: string;
  tracking_number: string;
  tracking_url: string;
}

export interface ShippingInfo {
  costs: {
    currency: string;
    shipping: string;
    total: string;
  };
  trackingUrl: string;
  trackingEvents: TrackingEvent[];
  trackingInfo: TrackingInfo;
  shippingAddress: {
    name: string;
    company: string;
    address1: string;
    address2: string;
    city: string;
    state_code: string;
    state_name: string;
    country_code: string;
    country_name: string;
    zip: string;
    phone: string;
    email: string;
    tax_number: string;
  };
}
