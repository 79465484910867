import RoyaltyModulePopover from '@/components/royalties/RoyaltyModulePopover';
import { Design } from '@/lib';
import IconLinkArrowRight from '@/lib/components/icons/IconLinkArrowRight';
import IconMoreInfo from '@/lib/components/icons/IconMoreInfo';

import { getFormattedAmount } from '@/views/admin/utils/currency-formatter';
import { Box, Button, HStack, Text } from '@chakra-ui/react';
import { useState } from 'react';

interface RoyaltiesBannerProps {
  product: Design;
  onViewIps: () => void;
}

const RoyaltiesBanner = ({ onViewIps, product }: RoyaltiesBannerProps) => {
  const [isHovered, setHovered] = useState(false);

  const {
    template: { currency, price, royaltyPercentage },
  } = product;

  const userEarnings = price * (royaltyPercentage / 100);

  return (
    <Box
      bg="green.300"
      borderLeft="2px solid"
      borderLeftColor="green.600"
      p="10px 28px 12px 16px"
      w="100%"
    >
      <HStack mb="6px" spacing="4px">
        <Text textStyle="body">
          You earn {getFormattedAmount(currency.name, userEarnings)} from every single sale!
        </Text>
        <RoyaltyModulePopover icon={<IconMoreInfo />} product={product} />
      </HStack>
      <Button
        onClick={onViewIps}
        fontWeight={600}
        colorScheme="green"
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        variant="link"
      >
        My Designs
        <IconLinkArrowRight color={isHovered && '#136400'} />
      </Button>
    </Box>
  );
};

export default RoyaltiesBanner;
