import DesignPreviewPage from '@/views/demo/pages/design/DesignPreviewPage';
import HomeGuest from '@/views/home/HomeGuest';
import ProductDetailsGuest from '@/views/product/ProductDetailsGuest';

import ProductsPageGuest from '@/views/products/ProductsPageGuest';

const PUBLIC_ROUTES = [
  {
    path: '/brands/:id',
    component: ProductsPageGuest,
  },
  {
    path: '/designs/:id',
    component: DesignPreviewPage,
  },
  {
    path: '/',
    exact: true,
    component: HomeGuest,
  },
  {
    path: '/products',
    exact: true,
    component: ProductsPageGuest,
  },
  {
    path: '/products/:id',
    exact: true,
    component: ProductDetailsGuest,
  },
];

export default PUBLIC_ROUTES;
